import type { FC } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import type { FetchUsersOutput, UseCreateUserMutation, UseFetchUserQuery } from '@acadeum/api';
import { userHasPermission } from '@acadeum/helpers';
import { Alert, Button } from '@acadeum/ui';
import { PlusIcon } from '@acadeum/icons';
import { useTranslate } from '@acadeum/translate';
import type { Id, InstitutionContactUser, User, UserRole, UserRoleWithUsers } from '@acadeum/types';
import { getAuthSelector } from '@acadeum/auth';

import { ContactCard } from '../ContactCard';
import type { ContactsList } from '../ContactsPage';

import styles from './ContactSection.module.scss';

export interface UserContact extends Pick<User,
  'id' |
  'firstName' |
  'lastName' |
  'middleName' |
  'title' |
  'email' |
  'isActive'
> {
  api: boolean;
  roles: Pick<UserRole, 'id' | 'name'>[];
  sftp: boolean;
  singleSignOn: boolean;
  status: 'INACTIVE' | 'ACTIVE' | 'VERIFIED' | 'UNVERIFIED' | 'PASSWORD_NOT_SET'
}

export interface ContactLocal {
  id: Id | string;
  type: string;
  user: InstitutionContactUser | undefined;
}

interface ContactSectionProps {
  useCreateUserMutation: UseCreateUserMutation;
  useFetchUserQuery: UseFetchUserQuery;
  title: string;
  description: string;
  contacts: ContactLocal[];
  roles: Pick<UserRoleWithUsers, 'id' | 'name' | 'description' | 'type' | 'users'>[];
  users?: FetchUsersOutput['results'],
  contactType: string;
  contactsList: ContactsList;
  // eslint-disable-next-line
  setContactsList: (value: any) => void;
  onUpdate: (flag: boolean) => void;
  onUpdateUsers: () => void;
}

export const ContactSection: FC<ContactSectionProps> = ({
  title,
  description,
  contacts: contacts_,
  roles,
  users,
  contactType,
  contactsList,
  setContactsList,
  onUpdate,
  onUpdateUsers,
  useFetchUserQuery,
  useCreateUserMutation
}) => {
  const [contacts, setContacts] = useState<ContactLocal[]>(contacts_);
  const [disabled, setDisabled] = useState<boolean>();

  const t = useTranslate('shared-admin-ui.ContactSection');

  const user = useSelector(getAuthSelector('user'));

  const onAddContact = () => {
    const id = getUniqId('temp');
    setDisabled(true);
    setContacts(prevState => ([...prevState, { id, type: contactType, user: undefined }]));
    setContactsList(prevState => [...prevState, { id, type: contactType, user: { id: undefined }, action: 'ADD' }]);
  };

  const onChangeContact = (contactId, user) => {
    setContacts(prevState =>
      prevState.map(contact =>
        contact.id === contactId ? { ...contact, user: user } : contact
      )
    );

    const exist = contactsList.find(item => {
      if (item.id === contactId && item.user && user) {
        item.user.id = user.id;
        return true;
      }
    });

    if (!exist) {
      setContactsList(prevState => [...prevState, { id: contactId, type: contactType, user: { id: user?.id }, action: 'UPDATE' }]);
    }

    setDisabled(false);
    onUpdate(true);
  };

  const onRemoveContact = (id) => {
    const haveContact = contactsList.some(_ => _.id === id && _.action === 'ADD');

    if (haveContact) {
      setContactsList(prevState => prevState.filter(_ => _.id !== id && _.action !== 'ADD'));
    } else {
      setContactsList(prevState => [...prevState, { id, type: contactType, action: 'REMOVE' }]);
    }

    setDisabled(false);
    setContacts(prevState => prevState.filter(contact => contact && contact.id !== id));
    onUpdate(true);
  };

  const isPrimaryContactExist = () => {
    return !!contacts.length;
  };

  return (
    <div className={styles.ContactSection}>
      <div className={styles.ContactSection__card}>
        <div className={styles.ContactSection__title}>
          {title}
        </div>
        <div className={styles.ContactSection__description}>
          <div className={styles.ContactSection__content}>
            {description}
          </div>
          {contacts?.map((contact, index) => {
            return (
              <ContactCard
                key={index}
                useCreateUserMutation={useCreateUserMutation}
                useFetchUserQuery={useFetchUserQuery}
                counter={index}
                contactType={contactType}
                title={title}
                contact={contact}
                roles={roles}
                users={users}
                onChangeContact={onChangeContact}
                onRemoveContact={onRemoveContact}
                canRemove={user && userHasPermission(user, 'homeContact:delete', {
                  orgId: user.institution.id,
                  ownerId: null
                })}
                canEdit={user && userHasPermission(user, 'homeContact:update', {
                  orgId: user.institution.id,
                  ownerId: null
                })}
                onUpdateUsers={onUpdateUsers}
              />
            );
          })}
          {contacts.length === 0 && contactType === 'PRIMARY_CONTACT' && (
            <Alert className={styles.ContactSection__notification} variant="warn">
              {t('emptyPrimaryContactMessage')}
            </Alert>
          )}
          {user && userHasPermission(user, 'homeContact:create', {
            orgId: user.institution.id,
            ownerId: null
          }) && (
            (contactType !== 'PRIMARY_CONTACT' || (contactType === 'PRIMARY_CONTACT' && !isPrimaryContactExist())) && (
              <Button
                className={styles.ContactSection__addButton}
                icon={PlusIcon}
                variant="secondary"
                disabled={disabled}
                onClick={onAddContact}
              >
                {t('addContact')}
              </Button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

function getUniqId(value) {
  return value + '_' + Math.random().toString(36).substr(2, 9);
}
