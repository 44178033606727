import type { ReactNode } from 'react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import formatUserName from 'common-lib/lib/formatUserName';

import { getAuthSelector } from '@acadeum/auth';
import { ChevronLeftIcon, ChevronRightIcon } from '@acadeum/icons';
import { Button, ChangeInstitutionModal, DataBlock, Separator, toast, UserPicture } from '@acadeum/ui';
import { useScreenSize } from '@acadeum/hooks';

import { useAppTemplateContext } from '../context';

import { MinimizeButton } from './MinimizeButton';
import type { MenuItemProps } from './MenuItem';
import { MenuItem } from './MenuItem';

import styles from './Sidebar.module.scss';

export interface SidebarProps {
  menu: MenuItemProps['item'][];
  menuItemsStyle?: 'column';
  location: MenuItemProps['location'];
  onBlur?: () => void;
  renderSidebarFooterCustomItems?: (options: { minimized: boolean }) => ReactNode;
}

export const Sidebar = React.forwardRef<HTMLElement, SidebarProps>(({
  menu,
  menuItemsStyle,
  location,
  onBlur,
  renderSidebarFooterCustomItems
}, ref) => {
  const { 
    minimized, 
    expanded, 
    toggleMinimized, 
    renderMenu, 
    setRenderMenu, 
    userMenu, 
    additionalMenu,
    useAdminChangeOwnInstitutionMutation,
    loginUrl
  } = useAppTemplateContext();
  const { isSmallScreen } = useScreenSize();

  const user = useSelector(getAuthSelector('user'));

  return (
    <aside
      ref={ref}
      onBlur={onBlur}
      className={classNames(styles.PageSidebar, {
        [styles['PageSidebar--minimized']]: minimized,
        [styles['PageSidebar--expanded']]: expanded,
        [styles['PageSidebar--menuItemsStyle-column']]: !minimized && menuItemsStyle === 'column'
      })}
    >
      {isSmallScreen && user && (
        <div>
          <UserInfo
            user={user}
            isOpen={renderMenu === 'user'}
            onToggle={() => setRenderMenu(renderMenu === 'app' ? 'user' : 'app')}
          />
          <Separator className={styles.Divider} m="none"/>
        </div>
      )}

      <nav className={styles['Sidebar-nav']}>
        {user && (renderMenu === 'app' || !isSmallScreen) && menu && (
          <ul className={styles['Sidebar-nav__list']}>
            {menu.map((item, index) => (
              <MenuItem
                key={index}
                item={item}
                menuItemsStyle={menuItemsStyle}
                minimized={minimized}
                location={location}
              />
            ))}
          </ul>
        )}

        {isSmallScreen && (
          <>
            {user && renderMenu === 'user' && userMenu && (
              <ul className={styles['Sidebar-nav__list']}>
                {userMenu && userMenu.map((item, index) => (
                  <MenuItem
                    key={index}
                    item={item}
                    menuItemsStyle={menuItemsStyle}
                    minimized={minimized}
                    location={location}
                  />
                ))}
              </ul>
            )}
            {renderMenu === 'app' && additionalMenu && (
              <>
                {user && (
                  <Separator className={styles.Divider} m="none"/>
                )}
                <ul className={styles['Sidebar-nav__list']}>
                  {additionalMenu.map((item, index) => (
                    <MenuItem
                      key={index}
                      item={item}
                      menuItemsStyle={menuItemsStyle}
                      minimized={minimized}
                      location={location}
                    />
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </nav>

      {isSmallScreen && !user && (
        <Button
          url={loginUrl}
          variant="white"
        >
          Login
        </Button>
      )}

      {!isSmallScreen && (
        <MinimizeButton
          className={styles.MinimizeButton}
          onClick={toggleMinimized}
          minimized={minimized}
        />
      )}

      {user && (
        (renderSidebarFooterCustomItems || isSmallScreen) && (
          <div className={styles.PageSidebar__footer}>
            <Separator className={styles.Divider} m="none"/>
            {isSmallScreen && (
              <>
                <DataBlock
                  mt="md"
                  mb="sm"
                  type="institution"
                  institution={user.institution}
                  className={styles.InstitutionLink}
                />
                <ChangeInstitution useAdminChangeOwnInstitutionMutation={useAdminChangeOwnInstitutionMutation}/>
              </>
            )}
            {renderSidebarFooterCustomItems?.({ minimized })}
          </div>
        )
      )}
    </aside>
  );
});

const ChangeInstitution = ({ useAdminChangeOwnInstitutionMutation }) => {
  const [show, setShow] = useState<boolean>();
  const { toggleExpanded } = useAppTemplateContext();
  const onClickOpen = () => {
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    toggleExpanded(true);
  };

  const [changeAdminInstitution] = useAdminChangeOwnInstitutionMutation();
  const onChangeInstitution = async (values) => {
    await changeAdminInstitution(values).unwrap();
    toast.success('Institution was successfully changed, reloading the page...');
    // Wait for the user to see the message
    await new Promise(resolve => setTimeout(resolve, 3000));
    // Reload the page to get the current user re-fetched
    window.location.reload();
  };

  return (
    <>
      <Button
        variant="text"
        className={styles.editInstitution}
        mb="md"
        onClick={onClickOpen}
      >
        Change Institution
      </Button>
      <ChangeInstitutionModal
        superAdmin
        type="institution"
        onHide={closeModal}
        show={show}
        onSubmit={onChangeInstitution}
      />
    </>
  );
};

function UserInfo({ user, isOpen, onToggle }) {
  return (
    <button onClick={onToggle} className={styles.UserInfo}>
      {isOpen && (
        <ChevronLeftIcon className={styles.UserInfo__arrow}/>
      )}
      <UserPicture
        size={48}
        user={user}
        className={styles.UserInfo__avatar}
      />
      <span className={styles.UserInfo__body}>
        <h3 className={styles.UserInfo__name}>
          {formatUserName(user)}
        </h3>
        <span className={styles.UserInfo__roles}>
          {user.roles.map(_ => _.name).join(', ')}
        </span>
      </span>

      {!isOpen && (
        <ChevronRightIcon className={styles.UserInfo__arrow}/>
      )}
    </button>
  );
}

UserInfo.propTypes = {
  user: PropTypes.object,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func
};
