import { getAlgoliaIndex } from './algolia';

function getInstitutionAffiliationIndex() {
  return getAlgoliaIndex('institutionAffiliations');
}

export async function getInstitutionAffiliationOptionsByName(name: string, parameters: {
  limit?: number
}) {
  const { limit } = parameters;

  const institutionAffiliationProperties = await getInstitutionAffiliationIndex().findAll({
    query: name,
    limit: limit
  });

  return institutionAffiliationProperties.map(getPropertyOptionForInstitution);
}

export async function getInstitutionAffiliationOptionByValue(id: number) {
  const institutionAffiliationProperties = await getInstitutionAffiliationIndex().get(String(id));

  if (institutionAffiliationProperties) {
    return getPropertyOptionForInstitution(institutionAffiliationProperties);
  }

  return null;
}

function getPropertyOptionForInstitution({objectID, name}) {
  return {
    value: Number(objectID),
    label: name
  };
}
