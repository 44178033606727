import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import type { IconSource } from '@acadeum/types';
import { Link } from '@acadeum/ui';
import { useScreenSize } from '@acadeum/hooks';

import { useAppTemplateContext } from '../../context';

import styles from './MenuItem.module.scss';

interface Item {
  icon: IconSource;
  url?: string;
  external?: boolean;
  onClick?: () => void;
  title: string;
  notificationCount?: number;
  isActive?: (pathname: string) => boolean;
}

export interface MenuItemProps {
  item: Item;
  menuItemsStyle?: 'column';
  minimized?: boolean;
  location: {
    pathname: string
  };
}

export const MenuItem: React.FC<MenuItemProps> = ({
  item,
  menuItemsStyle,
  minimized,
  location
}) => {
  const { isSmallScreen } = useScreenSize();
  const { setExpanded } = useAppTemplateContext();

  const {
    url,
    external,
    onClick: onClick_,
    title,
    icon: Icon,
    notificationCount,
    isActive: getIsActive
  } = item;

  const onClick = () => {
    setExpanded(false);
    onClick_?.();
  };

  const isActive = url === location.pathname
    || url?.indexOf(location.pathname + '/') === 0
    || getIsActive?.(location.pathname);

  return (
    <li className={styles.MenuItem}>
      <Link
        to={url}
        external={external}
        title={title}
        onClick={onClick}
        className={classNames(styles.link, {
          [styles['link--selected']]: isActive,
          [styles['link--minimized']]: minimized,
          [styles['link--menuItemsStyle-column']]: !minimized && !isSmallScreen && menuItemsStyle === 'column'
        })}
      >
        <span className={styles.iconContainer}>
          <Icon className={styles.icon}/>
          {typeof notificationCount === 'number'
            && notificationCount !== 0
            && menuItemsStyle !== 'column'
            && (
              <span className={styles.notificationCount}>
                {notificationCount}
              </span>
            )}
        </span>

        {!minimized && (
          <span>
            {item.title}
          </span>
        )}
      </Link>
    </li>
  );
};

MenuItem.propTypes = {
  item: PropTypes.any,
  menuItemsStyle: PropTypes.oneOf(['column']),
  minimized: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};
