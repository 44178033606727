import type { Id } from '@acadeum/types';

import type { UseJob } from './api';

export interface CourseSettingsInput {
  courseIds: Id[];
  courseSectionIds?: Id[];
  consortiumIds?: Id[];
  institutionIds?: Id[];
  pricing?: { institutionId: Id; value: number; }[];
}

export const getUseUpdateCourseAdvancedSettingsJob = (useJob: UseJob) => () => {
  const job = useJob();
  return (parameters: CourseSettingsInput) => job<void>('courseSettings-update', parameters);
};

export type UseUpdateCourseAdvancedSettingsJob = ReturnType<typeof getUseUpdateCourseAdvancedSettingsJob>
