// Checks for ISBN-10 or ISBN-13 format.
// https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s13.html
const ISBN_REG_EXP = /^(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/;

export default function isValidIsbn(string) {
  if (!ISBN_REG_EXP.test(string)) {
    return false;
  }

  // Validates ISBN checksum.

  // Remove non ISBN digits, then split into an array
  const chars = string.replace(/[- ]|^ISBN(?:-1[03])?:?/g, '').split('');
  // Remove the final ISBN digit from `chars`, and assign it to `last`
  const last = chars.pop();

  let sum = 0;
  let check;
  let checkX;
  let i;

  if (chars.length === 9) {
    // Compute the ISBN-10 check digit
    chars.reverse();
    for (i = 0; i < chars.length; i++) {
      sum += (i + 2) * parseInt(chars[i], 10);
    }
    check = 11 - (sum % 11);
    if (check === 10) {
      checkX = true;
    } else if (check === 11) {
      check = 0;
    }
  } else {
    // Compute the ISBN-13 check digit.
    for (i = 0; i < chars.length; i++) {
      sum += (i % 2 * 2 + 1) * Number(chars[i]);
    }
    check = 10 - (sum % 10);
    if (check === 10) {
      check = 0;
    }
  }

  if (checkX) {
    return last === 'X'
  }

  return check === Number(last);
}
