import useLocation from '../hooks/useLocation';
import useQueryParameters from '../hooks/useQueryParameters';

import getAuthCookieConfig from '../helpers/getAuthCookieConfig.js';
import onLoggedIn from '../helpers/onLoggedIn';
import onNotLoggedIn from '../helpers/onNotLoggedIn';

import { useAuthentication } from '@acadeum/auth';

import actions from '../actions';

const {
  setUser,
  setAccessTokenExpiresAt,
  setIsAuthenticationLoading,
  authenticate,
  refreshAccessToken
} = actions;

export default function useAuthentication_() {
  const location = useLocation();

  return useAuthentication({
    location,
    useQueryParameters,
    authCookieConfig: getAuthCookieConfig(),
    setUser,
    setAccessTokenExpiresAt,
    setIsAuthenticationLoading,
    async authenticate() {
      const user = await authenticate();
      return user;
    },
    async refreshAccessToken({ expiresIn: shouldExpireIn }) {
      const {
        token,
        expiresIn,
        expiresAt
      } = await refreshAccessToken({ expiresIn: shouldExpireIn });
      return {
        token,
        expiresIn,
        expiresAt
      };
    },
    onAuthenticated: onLoggedIn,
    onNotAuthenticated: onNotLoggedIn
  });
}
