import React from 'react';
import { usePicker } from '../context';
import { formatInputValues, high, low } from '../utils/formatters';
import TrashIcon, { DegreesIcon, LinearIcon, RadialIcon, StopIcon } from './icon';

const GradientType = () => {
  const { gradientType, onChange, value } = usePicker();
  const isLinear = gradientType === 'linear-gradient';
  const isRadial = gradientType === 'radial-gradient';

  const handleLinear = () => {
    const remaining = value.split(/,(.+)/)[1];
    onChange(`linear-gradient(90deg, ${remaining}`);
  };

  const handleRadial = () => {
    const remaining = value.split(/,(.+)/)[1];
    onChange(`radial-gradient(circle, ${remaining}`);
  };

  return (
    <div className="rbgcp-control-btn-wrapper">
      <div
        onClick={handleLinear}
        className="rbgcp-control-btn rbgcp-linear-btn"
        data-selected={isLinear}
        tabIndex={0}
        role="button"
        onKeyDown={() => {
          return;
        }}
      >
        <LinearIcon/>
      </div>
      <div
        onClick={handleRadial}
        className="rbgcp-control-btn rbgcp-radial-btn"
        data-selected={isRadial}
        tabIndex={0}
        role="button"
        onKeyDown={() => {
          return;
        }}
      >
        <RadialIcon/>
      </div>
    </div>
  );
};

const StopPicker = () => {
  const { currentLeft, handleGradient, currentColor } = usePicker();

  const handleMove = (newVal: string) => {
    handleGradient(currentColor, formatInputValues(parseInt(newVal), 0, 100));
  };

  return (
    <div
      className="rbgcp-control-btn-wrapper"
      style={{ paddingLeft: 8 }}
    >
      <StopIcon/>
      <input
        value={currentLeft}
        onChange={(e) => handleMove(e.target.value)}
        className="rbgcp-control-input"
      />
    </div>
  );
};

const DegreePicker = () => {
  const { degrees, onChange, value } = usePicker();

  const handleDegrees = (e: any) => {
    const newValue = formatInputValues(e.target.value, 0, 360);
    const remaining = value.split(/,(.+)/)[1];
    onChange(`linear-gradient(${newValue ?? 0}deg, ${remaining}`);
  };

  return (
    <div
      className="rbgcp-control-btn-wrapper rbgcp-control-input-wrap rbgcp-degree-input-wrap"
    >
      <DegreesIcon/>
      <input
        value={degrees}
        onChange={(e) => handleDegrees(e)}
        className="rbgcp-control-input rbgcp-degree-input"
      />
      <div
        className="rbgcp-degree-circle-icon"
        style={{
          position: 'absolute',
          right: degrees > 99 ? 0 : degrees < 10 ? 7 : 3,
          top: 1,
          fontWeight: 400,
          fontSize: 13
        }}
      >
        °
      </div>
    </div>
  );
};

const DeleteBtn = () => {
  const { colors, selectedColor, createGradientStr } = usePicker();

  const deletePoint = () => {
    if (colors?.length > 2) {
      const formatted = colors?.map((fc: any, i: number) => ({
        ...fc,
        value: i === selectedColor - 1 ? high(fc) : low(fc)
      }));
      const remaining = formatted?.filter(
        (_: any, i: number) => i !== selectedColor
      );
      createGradientStr(remaining);
    }
  };

  return (
    <div
      role="button"
      onClick={deletePoint}
      style={{ width: 28 }}
      className="rbgcp-control-icon-btn rbgcp-point-delete-btn"
      tabIndex={0}
      onKeyDown={() => {
        return;
      }}
    >
      <TrashIcon/>
    </div>
  );
};

const GradientControls = ({
  hideGradientType,
  hideGradientAngle,
  hideGradientStop
}: {
  hideGradientType?: boolean
  hideGradientAngle?: boolean
  hideGradientStop?: boolean
}) => {
  const { gradientType } = usePicker();
  return (
    <div
      style={{
        marginTop: 12,
        marginBottom: -4,
        justifyContent: 'space-between',
        paddingLeft: hideGradientType ? 4 : 0
      }}
      className="rbgcp-control-btn-wrapper"
    >
      {!hideGradientType && <GradientType/>}
      <div style={{ width: 53 }}>
        {!hideGradientAngle && gradientType === 'linear-gradient' && (
          <DegreePicker/>
        )}
      </div>
      {!hideGradientStop && <StopPicker/>}
      <DeleteBtn/>
    </div>
  );
};

export default GradientControls;
