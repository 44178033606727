import {
  getUseBulkUpsertCoursesJob,
  getUseJob,
  getUseUpdateCourseAdvancedSettingsJob,
  getUseBulkCreateOrUpdateStudentsJob
} from '@acadeum/api';

import { rtkApi } from './rtkApi';

export const useJob = getUseJob(rtkApi);

export const useBulkUpsertCoursesJob = getUseBulkUpsertCoursesJob(useJob);
export const useUpdateCourseAdvancedSettingsJob = getUseUpdateCourseAdvancedSettingsJob(useJob);
export const useBulkCreateOrUpdateStudents = getUseBulkCreateOrUpdateStudentsJob(useJob);
