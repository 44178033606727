import type { FC } from 'react';
import React from 'react';
import type { UseBulkUpsertCoursesJob } from '@acadeum/api';
import { useTranslate } from '@acadeum/translate';

import type { DataUploadPage2Props } from '../../../../components/DataUploadPage2';
import { DataUploadPage2 } from '../../../../components/DataUploadPage2';
import { COURSE_UPLOAD_SCHEMA, SECTION_SCHEMA, SESSION_SCHEMA, transformCourseUploadData } from './courseImportSchema';

import type { UseStepResult } from '@acadeum/hooks';

export interface UploadCoursesProps extends Pick<DataUploadPage2Props, 'cacheOptions'> {
  navigation: UseStepResult<unknown>['navigation'];
  onCoursesUpload: (options: { courses: object[], institutionId?: number }) => Promise<void>;
  useBulkCreateOrUpdate: UseBulkUpsertCoursesJob;
}

const getColumnSchema = (columnName) => COURSE_UPLOAD_SCHEMA[columnName] || SESSION_SCHEMA[columnName] || SECTION_SCHEMA[columnName];

export const UploadCourses: FC<UploadCoursesProps> = ({
  navigation,
  onCoursesUpload,
  useBulkCreateOrUpdate,
  cacheOptions
}) => {
  const t = useTranslate('shared-admin-ui.UploadCourses');
  const bulkCreateOrUpdate = useBulkCreateOrUpdate();

  const onUpload: DataUploadPage2Props['onUpload'] = async ({ institutionId, rows }) => {
    await onCoursesUpload({
      institutionId,
      courses: transformCourseUploadData(structuredClone(rows))
    });
    navigation.next();
  };

  const validate: DataUploadPage2Props['validate'] = async ({ institutionId, rows }) => {
    return await bulkCreateOrUpdate({
      institutionId,
      dryRun: true,
      courseSections: transformCourseUploadData(structuredClone(rows))
    });
  };

  return (
    <div>
      <DataUploadPage2
        cacheOptions={cacheOptions}
        templateUrl="https://dliov3t26vp8o.cloudfront.net/Data%20Upload%20Templates/Course.Upload.Template.xlsx"
        schema={COURSE_UPLOAD_SCHEMA}
        getColumnSchema={getColumnSchema}
        validate={validate}
        onUpload={onUpload}
        onBack={navigation.previous}
        submitText={t('next')}
      />
    </div>
  );
};
