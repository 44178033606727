/* eslint-disable */
import React, { MutableRefObject, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';

import {
  DeepPartial,
  FieldErrors,
  FormProvider,
  FormState,
  SubmitErrorHandler,
  useForm,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';

import { isHttpError, normalizeFormValues } from '@acadeum/helpers';

import { ERROR, PENDING, setStatus, SUCCESS } from '../FormSubmitAnnouncer';
import { onSubmitEnd } from '../FormSubmit';
import { OwnFormContext } from './context';

// For the component to work properly,
// you need to connect the FormSubmitAnnouncer to your project in _app,
// otherwise the application will break on every submit,
// see example `pages/_app`.

export const DEFAULT_PENDING_MESSAGE = 'The requested action is in progress...';
export const DEFAULT_ERROR_MESSAGE = 'The requested action was not successful. Please try again later or contact the Acadeum Support Team.';

export type OnSubmit<V extends {} = any, R = any> = (values: V, options: {
  clearForm: UseFormReset<any>;
  setError: UseFormSetError<any>;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  isDirty: FormState<any>['isDirty'];
}) => Promise<R> | R

type FormFunctionChildrenProps = {
  setValue: UseFormSetValue<any>;
  setError: UseFormSetError<any>;
  register: UseFormRegister<any>;
  getValues: UseFormGetValues<any>;
  errors: FormState<any>['errors'];
  watch: UseFormWatch<any>;
  isDirty: FormState<any>['isDirty'];
  isValid: FormState<any>['isValid'];
  clearForm: UseFormReset<any>;
}

export interface FormProps<V extends object = any> extends Omit<React.HTMLAttributes<HTMLFormElement>, 'children' | 'onSubmit' | 'onInvalid'> {
  autoFocus?: boolean;
  modal?: boolean;
  shouldUnregister?: boolean;
  defaultValues?: DeepPartial<any>;
  isNestedForm?: boolean;
  showPendingToast?: boolean | ((values: any) => boolean);
  pendingMessage?: string;
  onSubmit: OnSubmit<V>;
  children: React.ReactNode | ((props: FormFunctionChildrenProps) => React.ReactNode);
  loading?: React.FC;
  notification: {
    loading: ToastFunction;
    error: ToastFunction;
    toastDismiss?: (id: number) => void
  };
  onInvalid?: SubmitErrorHandler<any>;
  noValidate?: boolean;
  errors?: FieldErrors;
}

interface ToastFunction {
  (message: string): {
    id: string;
    dismiss: () => void;
    update?: (props) => void;
  } | number;
}

export type FormRef = {
  submit: () => void,
  reset: () => void
  focus: () => void
  setValue: UseFormSetValue<any>
}

export const Form = React.forwardRef((
  props: FormProps,
  ref: React.ForwardedRef<FormRef>
) => {
  const {
    autoFocus,
    modal,
    onSubmit,
    children,
    defaultValues,
    shouldUnregister = true,
    showPendingToast = true,
    pendingMessage = DEFAULT_PENDING_MESSAGE,
    isNestedForm,
    loading: Loading,
    notification,
    onInvalid,
    ...rest
  } = props;

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister,
    defaultValues,
    shouldUseNativeValidation: false
  });

  const childrenParameters = useMemo<FormFunctionChildrenProps>(() => ({
    setValue: methods.setValue,
    setError: methods.setError,
    register: methods.register,
    errors: methods.formState.errors,
    watch: methods.watch,
    isDirty: methods.formState.isDirty,
    isValid: methods.formState.isValid,
    getValues: methods.getValues,
    clearForm: () => methods.reset()
  }), [
    methods.setError,
    methods.setValue,
    methods.register,
    methods.formState.errors,
    methods.watch,
    methods.formState.isDirty,
    methods.formState.isValid,
    methods.reset
  ]);

  const setFocus = useCallback(() => {
    const values = methods.getValues();
    const fieldNames = Object.keys(values);
    if (fieldNames.length > 0) {
      methods.setFocus(fieldNames[0]);
    }
  }, [methods]);

  useEffect(() => {
    if (autoFocus) {
      setFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus, setFocus]);

  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  useImperativeHandle(ref, () => ({
    submit: () => inputRef.current.click(),
    reset: () => methods.reset(),
    focus: () => setFocus(),
    setValue: methods.setValue
  }));

  let toastDismiss: (() => void) | number;

  const onSubmitStart = useCallback(({ values }) => {
    setStatus(PENDING);
    if (typeof showPendingToast === 'boolean' ? showPendingToast : showPendingToast?.(values)) {
      const toast = notification.loading(pendingMessage);
      if (typeof toast === 'number') {
        toastDismiss = toast;
      } else {
        toastDismiss = toast.dismiss;
      }
    }
  }, [
    showPendingToast,
    notification.loading
  ]);

  const onDismissToast = () => {
    if (toastDismiss) {
      if (notification.toastDismiss && typeof toastDismiss === 'number') {
        notification.toastDismiss(toastDismiss);
      } else if (typeof toastDismiss !== 'number') {
        toastDismiss();
      }
    }
  }

  const onSubmitSuccess = useCallback(() => {
    onDismissToast();
    setStatus(SUCCESS);
  }, [
    notification
  ]);

  const onSubmitError = useCallback((error) => {
    console.error('Form error:', error);
    onDismissToast();
    notification.error(isHttpError(error) && error.data.message ? error.data.message : DEFAULT_ERROR_MESSAGE);
    setStatus(ERROR);
  }, [
    notification
  ]);

  const onSubmit_ = useCallback(async (values) => {
    normalizeFormValues(values);
    onSubmitStart({ values });
    // Starting from version `7.42.0`, `react-hook-form`'s `handleSubmit'
    // no longer catches the errors thrown from `onSubmit()` function,
    // so this `try`/`catch` block was added to catch such errors.
    try {
      await onSubmit(values, {
        clearForm: methods.reset,
        setError: methods.setError,
        setValue: methods.setValue,
        getValues: methods.getValues,
        isDirty: methods.formState.isDirty
      });
      onSubmitSuccess();
    } catch (error) {
      onSubmitError(error);
    } finally {
      onSubmitEnd({ setValue: methods.setValue });
    }
  }, [
    onSubmit,
    onSubmitStart,
    onSubmitSuccess,
    onSubmitError,
    methods
  ]);

  const onSubmitHandler = useCallback(async (event) => {
    if (isNestedForm) {
      event.stopPropagation();
    }
    try {
      await methods.handleSubmit(onSubmit_, onInvalid)(event);
    } catch (error) {
      console.log('error__', error)
    }
  }, [
    isNestedForm,
    onSubmit_,
    methods,
    onInvalid
  ]);

  const forceSubmitWithoutValidation = () => {
    const values = methods.getValues();
    void onSubmit_(values);
  };

  const ownContextValue = useMemo(() => ({
    forceSubmitWithoutValidation
  }), [forceSubmitWithoutValidation]);

  return (
    <>
      {Loading && !modal && methods.formState.isSubmitting && (
        <Loading />
      )}

      <OwnFormContext.Provider value={ownContextValue}>
        <FormProvider {...methods}>
          <form
            {...rest}
            onSubmit={onSubmitHandler}
          >
            {typeof children === 'function' ? children(childrenParameters) : children}
            <input ref={inputRef} type="submit" hidden />
          </form>
        </FormProvider>
      </OwnFormContext.Provider>
    </>
  );
});
