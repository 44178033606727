import { useMemo } from 'react';

import getLastDropDateForCourseEnrollment from 'common-lib/lib/getLastDropDateForCourseEnrollment';

import { useTranslate } from '@acadeum/translate';
import { getEnrollmentTypeLabel } from '@acadeum/helpers';

import { formatPhone, formatReason } from '../../helpers/format';

export default function useExportDataColumns({ finalized } = {}) {
  const t = useTranslate();

  return useMemo(() => {
    const denyReasonColumns = finalized
      ? [{
        id: 'Deny Reason',
        value: row => formatReason(row.denyReason, row.denyReasonNotes, 'CourseRegistrationRequest.denyReason')
      }]
      : [];

    return [{
      id: 'Status',
      value: row => row.status
    }, {
      id: 'Requested Date',
      value: row => row.createdAt
    }, {
      id: 'Last Day to Approve',
      value: row => row.section.session.lastAddDate
    }, {
      id: 'First Name',
      value: row => row.student.firstName
    }, {
      id: 'Middle Name',
      value: row => row.student.middleName
    }, {
      id: 'Last Name',
      value: row => row.student.lastName
    }, {
      id: 'HI Student ID',
      value: row => row.student.hiStudentId
    }, {
      id: 'Email',
      value: row => row.student.email
    }, {
      id: 'Phone',
      value: row => row.student.phone && formatPhone(row.student.phone)
    }, {
      id: 'Advisor Name',
      value: row => row.student.advisorName
    }, {
      id: 'Advisor Email',
      value: row => row.student.advisorEmail
    }, {
      id: 'Enrollment Type',
      value: row => getEnrollmentTypeLabel(row)
    }, {
      id: 'HI Course ID',
      value: row => row.courseSubstitute ? row.courseSubstitute.equivalentCourse.code : row.substituteCourseCode
    }, {
      id: 'HI Course Title',
      // `row.courseSubstitute.courseTitle` is deprecated.
      // It will be migrated to `row.courseSubstitute.equivalentCourse.title`.
      value: row => row.courseSubstitute ? row.courseSubstitute.equivalentCourse.title : row.substituteCourseTitle
    }, {
      id: 'Student Cost',
      value: row => row.cost
    }, {
      id: 'Credits',
      value: row => row.section.session.course.hours
    }, {
      id: 'Reason for Course Need',
      value: row => formatReason(row.registerReason, row.registerReasonNotes, 'CourseRegistrationRequest.registerReason')
    }, ...denyReasonColumns, {
      id: 'TI Course ID',
      value: row => row.section.session.course.code
    }, {
      id: 'TI Course Title',
      value: row => row.section.session.course.title
    }, {
      id: 'Course Cost',
      value: row => row.costForInstitution
    }, {
      id: 'Term',
      value: row => row.section.session.course.onDemand ? 'Open Learning' : row.section.session.term
    }, {
      id: 'Internal Term',
      value: row => row.section.session.internalTerm
    }, {
      id: 'Session',
      value: row => row.section.session.course.onDemand ? null : row.section.session.name
    }, {
      id: 'Internal Session',
      value: row => row.section.session.internalName
    }, {
      id: 'Section',
      value: row => row.section.number
    }, {
      id: 'Session Start Date',
      value: row => row.section.session.course.onDemand ? null : row.section.session.startDate
    }, {
      id: 'Session Add Date',
      value: row => row.section.session.course.onDemand ? null : row.section.session.lastAddDate
    }, {
      id: 'Session Drop Date',
      value: row =>  {
        if (row.section.session.course.onDemand && !row.startedAt) {
          return null;
        }
        return getLastDropDateForCourseEnrollment({
          onDemand: row.section.session.course.onDemand,
          onDemandDropDateDaysAfterStartDate: row.section.session.course.institution.onDemandCourseDropDateDaysAfterStartDate,
          startedAt: row.startedAt,
          lastDropDate: row.section.session.lastDropDate
        }).lastDropDate;
      }
    }, {
      id: 'Session End Date',
      value: row => row.section.session.course.onDemand ? null : row.section.session.endDate
    }, {
      id: 'Teaching Institution',
      value: row => row.section.session.course.institution.name
    }];
  }, [t]);
}
