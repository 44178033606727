import type { FC } from 'react';
import React, { useId, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import formatUserName from 'common-lib/lib/formatUserName';

import { getAuthSelector } from '@acadeum/auth';
import { BriefcaseIcon, EnvelopeIcon, GroupIcon, PhoneIcon } from '@acadeum/icons';
import { getErrorData, isAcadeumAdministrator } from '@acadeum/helpers';
import type { OnSubmit } from '@acadeum/ui';
import {
  CloseButton,
  Col,
  Email,
  extractRoleIdsFromValues,
  FormField,
  FormModal,
  Loader,
  Row,
  Select,
  toast
} from '@acadeum/ui';
import type { FetchUsersOutput, UseCreateUserMutation, UseFetchUserQuery } from '@acadeum/api';
import { useUpdateEffect } from '@acadeum/hooks';
import { useTranslate } from '@acadeum/translate';
import type { Id, UserRoleWithUsers } from '@acadeum/types';
import { YES_OR_NO_OPTIONS } from '@acadeum/selection-options';

import { useOnError } from '../../../providers/useOnError';

import type { ContactLocal } from '../ContactSection/ContactSection';
import styles from './ContactCard.module.scss';

export interface ContactCardProps {
  useCreateUserMutation: UseCreateUserMutation;
  useFetchUserQuery: UseFetchUserQuery;
  contactType: string;
  counter: number;
  title: string;
  contact: ContactLocal;
  roles: Pick<UserRoleWithUsers, 'id' | 'name' | 'description' | 'type' | 'users'>[];
  users?: FetchUsersOutput['results'],
  onChangeContact: (id: number | string, user) => void;
  onRemoveContact: (id: number | string) => void;
  onUpdateUsers: () => void;
  canEdit?: boolean;
  canRemove?: boolean;
}

export const ContactCard: FC<ContactCardProps> = ({
  contactType,
  counter,
  contact,
  roles,
  users,
  onChangeContact,
  onRemoveContact,
  onUpdateUsers,
  canRemove,
  canEdit,
  useFetchUserQuery,
  useCreateUserMutation
}) => {
  const t = useTranslate('shared-admin-ui.ContactCard');
  const onError = useOnError();

  const name = `${contactType}:${useId()}`;

  const [selectedUserId, setSelectedUserId] = useState();
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);

  const {
    data: user,
    isFetching: isLoadingUser,
    error
  } = useFetchUserQuery({
    id: selectedUserId!
  }, {
    skip: !selectedUserId
  });

  useUpdateEffect(() => {
    onChangeContact(contact?.id, user);
  }, [user]);

  const usersOptions = useMemo(() => users?.filter(user => user.status !== 'INACTIVE').map(user => ({
    value: user.id,
    label: formatUserName(user)
  })), [users]);

  const onRemove = () => {
    onRemoveContact(contact?.id);
  };

  const onContactChange = (userId) => {
    if (userId) {
      setSelectedUserId(userId);
    }
  };

  const onAfterUserCreate = async (id) => {
    await onUpdateUsers();
    setSelectedUserId(id);
  };

  if (error) {
    return onError(error);
  }

  return (
    <div className={styles.ContactCard}>
      <div className={styles.ContactCard__content}>
        <div className={styles.ContactCard__title}>
          {t('contact', { counter: ++counter })}
          {canRemove && (
            <CloseButton
              size="sm"
              aria-label={t('removeContact')}
              onClick={onRemove}
            />
          )}
        </div>

        <div className={styles.ContactCard__item}>
          {usersOptions && (
            <Select
              options={usersOptions}
              name={name}
              value={contact?.user?.id}
              readOnly={!canEdit}
              action={{
                content: `+ ${t('createUser')}`,
                onClick: () => setShowCreateUserModal(true)
              }}
              onChange={onContactChange}
            />
          )}
        </div>
        {isLoadingUser ? (
          <Loader topOffset="small"/>
        ) : (
          <>
            <div className={styles.ContactCard__item}>
              {!contact?.user?.firstName && !contact?.user?.lastName ? (<GroupIcon/>) : (<BriefcaseIcon/>)}
              {contact?.user?.title || t('none')}
            </div>

            <div className={styles.ContactCard__item}>
              <EnvelopeIcon/>
              {contact?.user?.email || t('none')}
            </div>

            <div className={styles.ContactCard__item}>
              <PhoneIcon/>
              {contact?.user?.phone || t('none')}
            </div>
          </>
        )}
      </div>
      <CreateUserModal
        show={showCreateUserModal}
        roles={roles}
        onHide={() => setShowCreateUserModal(false)}
        onAfterSubmit={onAfterUserCreate}
        useCreateUserMutation={useCreateUserMutation}
      />
    </div>
  );
};

interface CreateUserModalProps {
  show: boolean;
  roles: Pick<UserRoleWithUsers, 'id' | 'name' | 'description' | 'type' | 'users'>[];
  onHide: () => void;
  onAfterSubmit: (id: Id) => void;
  useCreateUserMutation: UseCreateUserMutation;
}

interface FormValues {
  groupOrOfficeAccount: boolean;
  title: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email: string;
  phone: string;
  phoneExt: string;
  role: number;
  sftp: boolean;
}

interface UserFormData {
  email: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  institutionId?: number;
  phone: string;
  phoneExt: string;
  title: string;
  roleIds: number[];
  sftp?: boolean;
  type: 'GROUP' | 'INDIVIDUAL';
}

interface FormData {
  data: UserFormData,
  clearForm: () => void
}

const CreateUserModal: FC<CreateUserModalProps> = ({
  show,
  roles,
  onHide,
  onAfterSubmit,
  useCreateUserMutation
}) => {
  const [createUserMutation] = useCreateUserMutation();

  const [groupOrOfficeAccount, setGroupOrOfficeAccount] = useState(false);
  const user = useSelector(getAuthSelector('user'));
  const t = useTranslate('shared-admin-ui.ContactCard');

  const roleOptions = useMemo(() => roles.map(role => ({
    value: role.id,
    label: role.name,
    description: role.description
  })), [roles]);

  const onAccountTypeChange = (e) => {
    setGroupOrOfficeAccount(e.target.checked);
  };

  const onCreateUser = async ({ data, clearForm }: FormData) => {
    try {
      const { id } = await createUserMutation({
        ...data,
        redirectToWebsite: 'account'
      }).unwrap();
      toast.success(t('successMessage', {
        userName: <strong>{formatUserName(data)}'s</strong>
      }));
      clearForm();
      onHide();
      onAfterSubmit(id);
    } catch (error: unknown) {
      const errorData = getErrorData(error);
      if (errorData.status === 409 || errorData.code === 'email_occupied') {
        return toast.error(<>{t('errors.email_occupied', { email: (address) => <Email address={address}/> })}</>);
      }
      throw error;
    }
  };

  const onSubmit: OnSubmit<FormValues> = async (originalValues: FormValues, { clearForm }) => {
    const { values, roleIds } = extractRoleIdsFromValues(originalValues, ['role']);
    const type = values.groupOrOfficeAccount ? 'GROUP' : 'INDIVIDUAL';
    delete values.groupOrOfficeAccount;

    const data: UserFormData = isAcadeumAdministrator(user) ? {
      ...values,
      type,
      roleIds,
      sftp: values.sftp === 'true',
      institutionId: user?.institution.id
    } : {
      ...values,
      type,
      sftp: values.sftp === 'true',
      roleIds: [roleIds]
    };

    if (data.roleIds.length === 0) {
      throw new Error(t('noRoleChosen'));
    }

    return await onCreateUser({ data, clearForm });
  };

  return (
    <FormModal
      show={show}
      title={t('createUser')}
      submitText={t('create', { global: true })}
      onCancel={onHide}
      onHide={onHide}
      onSubmit={onSubmit}
    >
      <FormField
        type="switch"
        name="groupOrOfficeAccount"
        label={t('form.groupAccount')}
        defaultValue={groupOrOfficeAccount}
        onChange={onAccountTypeChange}
      />
      <Row>
        {!groupOrOfficeAccount && (
          <>
            <Col md={6}>
              <FormField
                required
                type="text"
                name="firstName"
                label={t('form.firstName.label')}
                placeholder={t('form.firstName.placeholder')}
              />
            </Col>
            <Col md={6}>
              <FormField
                required
                type="text"
                name="lastName"
                label={t('form.lastName.label')}
                placeholder={t('form.lastName.placeholder')}
              />
            </Col>
            <Col md={6}>
              <FormField
                type="text"
                name="middleName"
                label={t('form.middleName.label')}
                placeholder={t('form.middleName.placeholder')}
              />
            </Col>
          </>
        )}
        <Col md={6}>
          <FormField
            required
            name="title"
            label={t('form.userTitle.label')}
            placeholder={t('form.userTitle.placeholder')}
          />
        </Col>
        <Col md={!groupOrOfficeAccount ? 12 : 6}>
          <FormField
            required
            name="email"
            label={t('form.email.label')}
            placeholder={t('form.email.placeholder')}
          />
        </Col>
        <Col md={6}>
          <FormField
            required
            type="phone"
            name="phone"
            label={t('form.phone.label')}
            placeholder={t('form.phone.placeholder')}
          />
        </Col>
        <Col md={6}>
          <FormField
            name="phoneExt"
            label={t('form.phoneExt.label')}
            placeholder={t('form.phoneExt.placeholder')}
          />
        </Col>
      </Row>
      <FormField
        required
        sameWidth
        type="select"
        name="role"
        label={t('form.role.label')}
        placeholder={t('form.role.placeholder')}
        options={roleOptions}
      />
      <Row>
        <Col md={12}>
          <FormField
            required
            border
            type="radio"
            name="sftp"
            label={t('form.SFTPAccess.label')}
            defaultValue={false}
            options={YES_OR_NO_OPTIONS}
          />
        </Col>
      </Row>
    </FormModal>
  );
};
