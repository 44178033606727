import { useMemo } from 'react';

import getLastDropDateForCourseEnrollment from 'common-lib/lib/getLastDropDateForCourseEnrollment';

import { useTranslate } from '@acadeum/translate';
import { getEnrollmentTypeLabel } from '@acadeum/helpers';

import { formatReason } from '../../../helpers/format';

export function useExportDataColumns({teaching }: {teaching: boolean}) {
  const t = useTranslate();

  return useMemo(() => {
    const otherInstitutionColumn = {
      id: teaching ? 'Teaching Institution' : 'Home Institution',
      value: row => row.courseSection.course.institution.name
    };

    return [
      {
        id: 'Status',
        value: row => row.status
      },
      otherInstitutionColumn,
      {
        id: 'Enrollment Type',
        value: row => getEnrollmentTypeLabel(row)
      }, {
        id: teaching ? 'TI Course ID' : 'HI Course ID',
        value: row => row.courseSection.course.code
      }, {
        id: teaching? 'TI Course Title' : 'HI Course Title',
        value: row => row.courseSection.course.title
      }, {
        id: 'Credits',
        value: row => row.courseSection.course.hours
      }, {
        id: 'Term',
        value: row => row.courseSection.course.onDemand ? 'Open Learning' : row.courseSection.term
      }, {
        id: 'Session Start Date',
        value: row => row.courseSection.course.onDemand ? null : row.courseSection.startDate
      }, {
        id: 'Session Add Date',
        value: row => row.courseSection.course.onDemand ? null : row.courseSection.lastAddDate
      }, {
        id: 'Session Drop Date',
        value: row => {
          if (row.courseSection.course.onDemand && !row.startedAt) {
            return null;
          }
          return getLastDropDateForCourseEnrollment({
            onDemand: row.courseSection.course.onDemand,
            onDemandDropDateDaysAfterStartDate: row.courseSection.course.institution.onDemandCourseDropDateDaysAfterStartDate,
            startedAt: row.startedAt,
            lastDropDate: row.courseSection.lastDropDate
          }).lastDropDate;
        }
      }, {
        id: 'Session End Date',
        value: row => row.courseSection.course.onDemand ? null : row.courseSection.endDate
      }, {
        id: 'Enroll Reason',
        value: row => formatReason(row.enrollReason, row.enrollReasonNotes, 'Enrollments.enrollReason')
      }
    ];
  }, [t]);
}
