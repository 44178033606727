import getLastDateToDropFromCourseEnrollment from './getLastDateToDropFromCourseEnrollment.js';

export default function getLastDropDateForCourseEnrollment({
  onDemand,
  onDemandDropDateDaysAfterStartDate,
  startedAt,
  lastDropDate
}) {
  if (onDemand) {
    // Validate that `startedAt` property is not `null`.
    // If `startedAt` is `null` then the course hasn't started yet.
    // In that case, one could drop at any time.
    if (!startedAt) {
      return {
        lastDropDate: null,
        lastDropDateIsInUtc0TimeZone: false
      };
    }

    return {
      lastDropDate: getLastDateToDropFromCourseEnrollment({
        onDemand,
        onDemandDropDateDaysAfterStartDate,
        startedAt,
        lastDropDate
      }),
      lastDropDateIsInUtc0TimeZone: false
    };
  }

  return {
    lastDropDate,
    lastDropDateIsInUtc0TimeZone: true
  };
}
