import React from 'react';
import type { FC } from 'react';

import { useTranslate } from '@acadeum/translate';

import { useGetInstitutionAccreditationInputParams } from '../../utils/institutionAccreditationAutocomplete';

import { FormField } from '../FormField';

export interface InstitutionAccreditationAutocompleteProps {
  [key: string]: any;
  label?: string;
  name: string;
  placeholder?: string;
  notFoundLabel?: string;
  noInputLabel?: string;
  onChange?: (valueOrEvent: any, ...rest: any[]) => void;
}

export const InstitutionAccreditationAutocomplete: FC<InstitutionAccreditationAutocompleteProps> = ({
  label,
  name,
  placeholder,
  notFoundLabel,
  noInputLabel,
  onChange,
  ...rest
}) => {
  const t = useTranslate('ui.InstitutionAccreditationAutocomplete');

  const getInstitutionAccreditationInputParams = useGetInstitutionAccreditationInputParams();
  if (!getInstitutionAccreditationInputParams) {
    throw new Error('getInstitutionAccreditationInputParams is not defined');
  }

  const institutionAccreditationInputParams = getInstitutionAccreditationInputParams({ limit: 20 });

  return (
    <FormField
      {...rest}
      {...institutionAccreditationInputParams}
      name={name}
      type="select"
      placeholder={placeholder || t('placeholder')}
      label={label || t('label')}
      notFoundLabel={notFoundLabel || t('notFound')}
      noInputLabel={noInputLabel || t('noInput')}
      onChange={onChange}
    />
  );
};
