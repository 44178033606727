import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import reportError from 'common-lib/lib/reportError';

import {
  AppLoading,
  Breadcrumbs,
  ConfirmActionModal,
  FormatDateWithTime,
  Link,
  RadioCard,
  Select,
  Text,
  Title,
  toast
} from '@acadeum/ui';
import { useTranslate } from '@acadeum/translate';
import { userHasPermission, parseQueryError } from '@acadeum/helpers';
import type { AuthCookieConfig } from '@acadeum/auth';
import { getAuthSelector } from '@acadeum/auth';
import type { UseFetchSingleSignOnConfigQuery, UseResetSingleSignOnConfigMutation } from '@acadeum/api';

import { SingleSignOnSamlSettingsForm } from './SingleSignOnSamlSettingsForm';

import { useSettingsRoutes } from '../../hooks/useSettingsRoutes';
import { useLocation } from '../../providers/useLocation';
import { useApp } from '../../providers/useApp';

import styles from './AuthenticationPage.module.scss';

export interface AuthenticationPageProps {
  authCookieConfig: AuthCookieConfig | undefined;
  useFetchSingleSignOnConfigQuery: UseFetchSingleSignOnConfigQuery;
  useResetSingleSignOnConfigMutation: UseResetSingleSignOnConfigMutation;
  apiUrl: string;
}

export const AuthenticationPage: FC<AuthenticationPageProps> = ({
  authCookieConfig,
  useResetSingleSignOnConfigMutation,
  useFetchSingleSignOnConfigQuery,
  apiUrl
}) => {
  const t = useTranslate('shared-admin-ui.AuthenticationPage');
  const location = useLocation();
  const { app } = useApp();
  const { getSettingsUrl } = useSettingsRoutes();

  useEffect(() => {
    const error_ = location.query.error;
    if (error_) {
      const error = parseQueryError(error_);
      const errorMessage = error?.message || t('errorMessage');
      toast.error(errorMessage);
      const newError = new Error(errorMessage);
      // @ts-expect-error
      newError.data = error;
      reportError(newError);
    } else if (location.query.success) {
      toast.success(t('successMessage'));
    }
  }, []);

  const [domain, setDomain] = useState<'admin' | 'student'>('admin');
  const { data, isFetching } = useFetchSingleSignOnConfigQuery(domain);

  return (
    <div className={styles.root}>
      <Breadcrumbs
        children={[[t(app === 'admin' ? 'general' : 'settings', { global: true }), getSettingsUrl()], t('title')]}
      />
      <Title marginBottom="sm">
        {t('title')}
      </Title>

      <LastUpdated updatedAt={isFetching ? undefined : data?.updatedAt} />

      <Select
        value={domain}
        onChange={setDomain}
        className={styles.domain}
        options={[
          { value: 'admin', label: t('adminApp') },
          { value: 'student', label: t('studentApp') }
        ]}
      />

      <Text mb="lg">
        {t('description', {
          linkToConfigurationManual: (children) => (
            <Link target="_blank" to="https://dliov3t26vp8o.cloudfront.net/Documents/Single-Sign-On.pdf">
              {children}
            </Link>
          )
        })}
      </Text>

      {isFetching ? (
        <AppLoading inline="medium" />
      ) : (
        <AuthenticationSettingsForm
          domain={domain}
          samlConfig={data?.saml}
          authCookieConfig={authCookieConfig}
          useResetSingleSignOnConfigMutation={useResetSingleSignOnConfigMutation}
          apiUrl={apiUrl}
        />
      )}
    </div>
  );
};


function AuthenticationSettingsForm({
  samlConfig,
  domain,
  useResetSingleSignOnConfigMutation,
  authCookieConfig,
  apiUrl
}) {
  const t = useTranslate('shared-admin-ui.AuthenticationPage.AuthenticationSettingsForm');

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(samlConfig ? 'saml' : 'local');

  const user = useSelector(getAuthSelector('user'));

  const canEditOrReset = user && userHasPermission(
    user,
    (samlConfig ? 'authenticationSettings:delete' : 'authenticationSettings:update'),
    { ownerId: null, orgId: user.institution.id }
  );

  const onSelectCard = async (id) => {
    if (id === 'local') {
      if (samlConfig) {
        return setShowConfirmModal(true);
      }
    }
    setSelectedCardId(id);
  };

  const [resetSingleSignOnConfig] = useResetSingleSignOnConfigMutation();

  const onConfirmChangeToLocalAuth = async () => {
    setShowConfirmModal(false);
    await resetSingleSignOnConfig(domain).unwrap();
    setSelectedCardId('local');
  };

  return (
    <>
      <RadioCard.Group
        name="authentication"
        value={selectedCardId}
        onChange={onSelectCard}
        disabled={!canEditOrReset}
      >
        <RadioCard
          label={t('acadeumAuth')}
          value="local"
        />
        <RadioCard
          label={t('samlAuth')}
          value="saml"
        >
          <SingleSignOnSamlSettingsForm
            {...samlConfig}
            authCookieConfig={authCookieConfig}
            domain={domain}
            apiUrl={apiUrl}
          />
        </RadioCard>
      </RadioCard.Group>

      <ConfirmActionModal
        title={t('modal.title')}
        description={t('modal.description')}
        submitText={t('modal.submit')}
        show={showConfirmModal}
        onSubmit={onConfirmChangeToLocalAuth}
        onHide={() => setShowConfirmModal(false)}
      />
    </>
  );
}

function LastUpdated({ updatedAt }) {
  const t = useTranslate('shared-admin-ui.AuthenticationPage');

  const renderedDate = updatedAt instanceof Date ? (
    <FormatDateWithTime
      date={updatedAt}
      utc={false}
    />
  ) : (
    <>&mdash;</>
  );

  return (
    <Text color="grey">
      {t('lastUpdate', { time: renderedDate })}
    </Text>
  );
}
