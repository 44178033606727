const TWO_CONSECUTIVE_SPACES = /\s\s/;

export function validateNoConsecutiveSpaces(value?: string) {
  if (typeof value !== 'string') {
    return 'Value must be a string.';
  }

  if (TWO_CONSECUTIVE_SPACES.test(value)) {
    return 'Value must not contain consecutive spaces.';
  }
}

export const validateSessionName = validateNoConsecutiveSpaces;
export const validateTermName = validateNoConsecutiveSpaces;
