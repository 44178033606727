import type { ForwardedRef, ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';

import { LoadingEllipsis } from '@acadeum/core-ui';

import styles from './Input.module.scss';

export interface InputComponentProps extends React.InputHTMLAttributes<HTMLInputElement> {
  multiline?: never;
  autoRows?: never;
}

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  multiline: true;
  autoRows?: boolean;
  type?: never;
}

export type InputProps<M extends boolean = false> = (M extends true ? TextAreaProps : InputComponentProps) & {
  error?: string;
  loading?: boolean;
  appearance?: 'white';
  addonPrefix?: ReactNode;
  addonSuffix?: ReactNode;
};

type InputRef<M extends boolean = false> = M extends true ? HTMLTextAreaElement : HTMLInputElement;

const InputComponent = <M extends boolean = false>({
  id,
  error,
  loading,
  className,
  disabled,
  hidden,
  multiline,
  appearance,
  addonPrefix,
  addonSuffix,
  autoRows,
  type = 'text',
  onFocus: propsOnFocus,
  onBlur: propsOnBlur,
  ...rest
}: InputProps<M>, ref: ForwardedRef<InputRef<M>>) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const onFocus = (event) => {
    propsOnFocus?.(event);
    setIsFocused(true);
  };
  const onBlur = (event) => {
    propsOnBlur?.(event);
    setIsFocused(false);
  };

  const rows = multiline
    ? autoRows ? (isFocused ? 5 : 1) : 5
    : undefined;

  return (
    <div
      hidden={hidden}
      className={classNames(className, styles.wrapper, {
        [styles.error]: error,
        [styles.white]: appearance === 'white'
      })}
    >
      {addonPrefix && (
        <span className={classNames(styles.addon, styles.addonPrefix)}>{addonPrefix}</span>
      )}
      <span className={styles.inputWrapper}>
        {React.createElement(multiline ? 'textarea' : 'input', {
          ...rest,
          id,
          ref,
          disabled,
          className: styles.input,
          rows,
          type: multiline ? undefined : type,
          step: type === 'number' ? 'any' : undefined,
          'aria-invalid': !!error,
          'aria-describedby': id ? id + '-hint' : undefined,
          onFocus,
          onBlur
        })}
      </span>
      {loading && (
        <LoadingEllipsis className={styles.LoadingEllipsis}/>
      )}
      {addonSuffix && (
        <span className={classNames(styles.addon, styles.addonSuffix)}>{addonSuffix}</span>
      )}
    </div>
  );
};

export const Input = React.forwardRef(InputComponent);
