export default function fetchAllRecordsInBatches(callback) {
  const pageSize = 100;
  let page = 1;
  let allRecords = [];

  async function fetchRecords() {
    const { results } = await callback({ page, pageSize });

    allRecords = allRecords.concat(results);

    if (results.length === pageSize) {
      page += 1;
      return fetchRecords();
    } else {
      return allRecords;
    }
  }

  return fetchRecords();
}
