import { ReduxModule } from 'react-pages';

import COURSE_ENROLLMENT_FINALIZED_STATUSES from 'common-lib/constants/courseEnrollmentFinalizedStatuses.json';
import COURSE_ENROLLMENT_ACCEPTED_STATUSES from 'common-lib/constants/courseEnrollmentAcceptedStatuses.json';

import { getStudentEnrollments, isAwaitingGrade, isGradeEnrollment } from '../helpers/enrollments';
import fetchAllRecordsInBatches from '../helpers/fetchAllRecordsInBatches';

const redux = new ReduxModule();

export const fetchEnrollmentRequestsTM = redux.action(
  () => (http) => fetchAllRecordsInBatches((query) => http.get('/enrollmentrequeststm', query)),
  // The API returns `courses` instead of `enrollmentRequests`
  // and expands `courses` into `enrollmentRequests` on client side
  // because otherwise the output JSON would blow out of proportions
  // due to massive duplication, and even throw `body size is too long`.
  (state, courses) => {
    const enrollmentRequests = transformEnrollmentRequests(courses);
    const teachingEnrollments = getStudentEnrollments(enrollmentRequests);
    const newEnrollmentRequests = enrollmentRequests.filter(_ => _.status === 'REQUESTED');
    const acceptedTeachingEnrollments = teachingEnrollments.filter(_ => COURSE_ENROLLMENT_ACCEPTED_STATUSES.includes(_.status));
    const finalizedTeachingEnrollments = teachingEnrollments.filter(_ => COURSE_ENROLLMENT_FINALIZED_STATUSES.includes(_.status));
    return {
      ...state,
      enrollmentRequests,
      newEnrollmentRequestsCount: newEnrollmentRequests.length,
      acceptedTeachingEnrollmentsCount: acceptedTeachingEnrollments.length,
      finalizedTeachingEnrollmentsCount: finalizedTeachingEnrollments.length,
      teachingGradesCount: teachingEnrollments.filter(isGradeEnrollment).length,
      awaitingGradesCount: teachingEnrollments.filter(isAwaitingGrade).length
    };
  }
);

export const fetchAllTeachingEnrollmentsAcadeumAdministrator = redux.action(
  ({ page, pageSize }) => async (http) => {
    return await http.get('/all-teaching-enrollments', { page, pageSize });
  },
  (state, { enrollments, totalCount }) => ({
    ...state,
    allTeachingEnrollments: enrollments,
    allTeachingEnrollmentsCount: totalCount
  })
);

export const fetchEnrollmentRequestsTMForExport = redux.action(
  (ids) => async (http) => {
    // The API returns `courses` instead of `enrollmentRequests`
    // and expands `courses` into `enrollmentRequests` on client side
    // because otherwise the output JSON would blow out of proportions
    // due to massive duplication, and even throw `body size is too long`.
    const courses = await http.get('/enrollmentrequeststm', {
      dataExport: '✓',
      // Compress the list of IDs so that it fits into the URL query max length limit.
      // https://github.com/Acadeum/Tickets/issues/1293
      ids: ids && ids.sort((a, b) => a - b).map((id, i) => i === 0 ? id : id - ids[i - 1]).join('.')
    });
    return transformEnrollmentRequests(courses);
  }
);

export const approveEnrollmentRequestTM = redux.action(
  (id) => (http) => {
    Intercom('trackEvent', 'approve enrollment request TM', { id });
    return http.put(`/enrollmentrequeststm/approve/${id}`);
  }
);

export const fetchTeachingEnrollmentRequest = redux.action(
  (id) => (http) => http.get(`/teaching-enrollment-requests/${id}`),
  'teachingEnrollmentRequest'
);

export const getTeachingEnrollmentsCounts = redux.action(
  () => (http) => http.get('/counts/teaching-enrollments'),
  (state, counts) => ({
    ...state,
    ...counts
  })
);

export const setNewEnrollmentRequestsCount = redux.simpleAction(
  (state, count) => ({
    ...state,
    newEnrollmentRequestsCount: count
  })
);

export const setTeachingGradesCount = redux.simpleAction(
  (state, count) => ({
    ...state,
    teachingGradesCount: count
  })
);

export const setAwaitingGradesCount = redux.simpleAction(
  (state, count) => ({
    ...state,
    awaitingGradesCount: count
  })
);

export default redux.reducer();

// function getEnrollmentRequest(enrollmentRequests, id) {
//   for (const enrollmentRequest of enrollmentRequests) {
//     // `id` is string when it's taken from route params.
//     // `id` is a number when manually refreshing the enrollment request
//     // after accepting/denying requested student enrollments.
//     if (String(enrollmentRequest.id) === String(id)) {
//       return enrollmentRequest;
//     }
//   }
// }

// function getStudentEnrollmentAndEnrollmentRequest(enrollmentRequests, id) {
//   for (const enrollmentRequest of enrollmentRequests) {
//     const enrollments = enrollmentRequest.enrollments;
//     for (const studentEnrollment of enrollments) {
//       if (studentEnrollment.id.toString() === id) {
//         return { enrollmentRequest, studentEnrollment };
//       }
//     }
//   }
// }

// The API returns `courses` instead of `enrollmentRequests`
// and expands `courses` into `enrollmentRequests` on client side
// because otherwise the output JSON would blow out of proportions
// due to massive duplication, and even throw `body size is too long`.
export function transformEnrollmentRequests(courses) {
  let enrollmentRequests = [];

  // Transform `courses` to `enrollmentRequests`.
  for (const course of courses) {
    for (const session of course.sessions) {
      session.course = course;
      delete session.courseId;
      for (const section of session.sections) {
        section.session = session;
        delete section.sessionId;
        for (const enrollmentRequest of section.enrollmentRequests) {
          enrollmentRequest.section = section;
          delete enrollmentRequest.sectionId;
          course.institution = enrollmentRequest.institution;
          delete course.institutionId;
          // Set `student.institution`.
          // For example, it is used in `<EnrollmentsTableModal/>` for showing
          // `HOME INSTITUTION` column content.
          const enrollments = enrollmentRequest.enrollments;
          for (const studentEnrollment of enrollments) {
            studentEnrollment.student.institution = enrollmentRequest.institution;
          }
        }
        enrollmentRequests = enrollmentRequests.concat(section.enrollmentRequests);
      }
    }
  }

  // Sort by "Date Requested" descending.
  enrollmentRequests.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  return enrollmentRequests;
}
