import config from 'config';

import * as Sentry from '@sentry/react';

import packageJsonConfig from '../../../package.json';

const { version } = packageJsonConfig;

if (config.sentry) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: process.env.STAGE,
    release: version,
    // Replay may only be enabled for the client-side
    integrations: [Sentry.replayIntegration()],

    // Tracing: Capture 100% of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}
