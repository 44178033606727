import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import getLastDropDateForCourseEnrollment from 'common-lib/lib/getLastDropDateForCourseEnrollment';

import { useTranslate } from '@acadeum/translate';
import { getEnrollmentTypeLabel } from '@acadeum/helpers';
import { Button, DataBlock, EmptyState, HStack, Table, Tag, Text } from '@acadeum/ui';
import { EyeIcon, FileImportIcon } from '@acadeum/icons';

import Section from '../../components/Section';

import type { ReduxState } from '../../helpers/app.types';
import { loadEnrollmentsPage } from '../../helpers/routes';

import { useExportDataColumns } from './useExportDataColumns';

import ModalInfo from './ui/ModalInfo';

import './EnrollmentRequests.sass';

const EnrollmentRequests = () => {
  const t = useTranslate('EnrollmentRequests');

  const requests = useSelector((state: ReduxState) => state.enrollmentRequestsTM.enrollmentRequests);
  const requestsRequested = getNewEnrollmentRequests(requests);

  const exportDataColumns = useExportDataColumns();

  const columns = useMemo(() => [
    {
      id: 'course',
      accessorFn: (row) => `${row.section.session.course.title} ${row.section.session.course.code}`,
      header: t('course'),
      cell: ({ row }) => <DataBlock type="course" course={row.original.section.session.course}/>
    },
    {
      accessorKey: 'institution.name',
      header: t('homeInstitution'),
      cell: ({ row }) => <DataBlock type="institution" institution={row.original.institution}/>
    },
    {
      accessorKey: 'type',
      header: t('type'),
      cell: ({ row }) => (
        <Tag>{getEnrollmentTypeLabel(row.original.enrollments[0])}</Tag>
      )
    },
    {
      id: 'session',
      accessorFn: (original) => `${original.section.session.name} ${original.section.session.term}`,
      header: t('termAndSession'),
      enableSorting: true,
      meta: {
        emptyWhen: ({ row }) => row.section.session.course.onDemand
      },
      cell: ({ row }) => <DataBlock type="term&session" session={row.original.section.session}/>
    },
    {
      accessorKey: 'dates',
      header: t('dates'),
      enableSorting: true,
      meta: {
        emptyWhen: ({ row }) => row.section.session.course.onDemand && !row.startedAt
      },
      cell: ({ row }) => {
        if (row.original.section.session.course.onDemand) {
          if (row.original.startedAt) {
            const { lastDropDate, lastDropDateIsInUtc0TimeZone } = getLastDropDateForCourseEnrollment({
              onDemand: row.original.section.session.course.onDemand,
              onDemandDropDateDaysAfterStartDate: row.original.section.session.course.institution.onDemandCourseDropDateDaysAfterStartDate,
              startedAt: row.original.startedAt,
              lastDropDate: row.original.section.session.lastDropDate
            });
            if (lastDropDate) {
              return (
                <Text as="div" nowrap>
                  <HStack gap="xs">
                    Drop Date:
                    <DataBlock
                      date={lastDropDate}
                      utc={lastDropDateIsInUtc0TimeZone}
                      type="date"
                      month="short"
                    />
                  </HStack>
                </Text>
              );
            }
          }
          return null;
        }

        return (
          <DataBlock
            type="courseSessionDates"
            withLastDropDate
            session={row.original.section.session}
          />
        );
      }
    },
    {
      accessorKey: 'seatsRequested',
      header: t('seats')
    },
    {
      accessorKey: 'createdAt',
      header: t('submittedOn'),
      enableSorting: true,
      cell: ({ row }) => (
        <DataBlock
          utc
          type="date"
          month="long"
          as="span"
          date={row.original.createdAt}
        />
      )
    },
    {
      id: 'actions',
      cell: ({ row, downloadRow }) => <ActionsCell row={row} downloadRow={downloadRow} />
    }
  ], []);

  return (
    <Section
      title={t('title')}
    >
      {requestsRequested.length === 0 ? (
        <EmptyState
          icon={FileImportIcon}
          title={t('noData')}
        />
      ) : (
        <Table
          id="enrollmentRequests"
          enableGlobalFilter
          hasColumnVisibility
          enableRowSelection
          columns={columns}
          data={requestsRequested}
          exportOptions={{
            type: 'xlsx',
            fileName: t('title'),
            exportDataColumns
          }}
        />
      )}
    </Section>
  );
};

EnrollmentRequests.meta = () => ({
  title: 'New Students'
});

EnrollmentRequests.load = async ({ user }) => {
  await loadEnrollmentsPage({ user });
};

EnrollmentRequests.breadcrumbs = () => ['Enrollment Requests'];

export default EnrollmentRequests;

function getNewEnrollmentRequests(enrollmentRequests) {
  return enrollmentRequests.filter(_ => _.status === 'REQUESTED');
}

function ActionsCell({
  row,
  downloadRow
}) {
  const t = useTranslate('EnrollmentRequests');
  const [show, setShow] = useState(false);

  return (
    <>
      <Button
        variant="tertiary"
        onClick={() => setShow(true)}
        icon={EyeIcon}
      >
        {t('review')}
      </Button>
      <ModalInfo
        show={show}
        onHide={setShow}
        data={row.original}
        downloadRow={downloadRow}
      />
    </>
  );
}
