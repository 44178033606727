import get from 'lodash/get';
import { Integer, URL } from 'read-excel-file';

import { FormField } from '@acadeum/ui';
import {
  COURSE_LEVELS,
  COURSE_DELIVERY_METHODS,
  COURSE_LEVEL_OPTIONS,
  COURSE_DELIVERY_METHOD_OPTIONS,
  YES_OR_NO_OPTIONS,
  TERM_OPTIONS
} from '@acadeum/selection-options';

import {
  validateCCEraDate,
  validateNonNegativeNumber,
  validateOneOf,
  validateSessionName,
  validateTermName,
  validateISBN
} from '@acadeum/validate';

const validateCourseLevel = validateOneOf(COURSE_LEVELS);
const validateCourseDeliveryMethod = validateOneOf(COURSE_DELIVERY_METHODS);

function validateCourseSectionDate(index: number) {
  return (value, values) => {
    const ccEraError = validateCCEraDate(value);
    if (ccEraError) {
      return ccEraError;
    }

    const onDemand = get(values, `rows.${index}.onDemand`);
    if (!onDemand) {
      if (!value) {
        return 'This field is required when not marked as On-Demand.';
      }
    }
  };
}

const parseText = (value) => {
  if (typeof value !== 'string') {
    return null;
  }
  if (value.toLowerCase() === 'none') {
    return null;
  }
  return value.replace(/\\n/g, '\n');
};

const COURSE_SCHEMA = {
  'COURSE ID': {
    prop: 'code',
    type: String,
    required: true,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        required
        name={`rows.${props.row.index}.code`}
      />
    )
  },
  'COURSE TITLE': {
    prop: 'title',
    type: String,
    required: true,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        required
        name={`rows.${props.row.index}.title`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'CATEGORY 1': {
    prop: 'c1',
    type: String,
    required: true,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        required
        name={`rows.${props.row.index}.c1`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'CATEGORY 2': {
    prop: 'c2',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.c2`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'CATEGORY 3': {
    prop: 'c3',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.c3`}
      />
    )
  },
  'FACULTY 1 NAME': {
    prop: 'f1Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.f1Name`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 2 NAME': {
    prop: 'f2Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.f2Name`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 3 NAME': {
    prop: 'f3Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.f3Name`}
      />
    )
  },
  'FACULTY 4 NAME': {
    prop: 'f4Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.f4Name`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 5 NAME': {
    prop: 'f5Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.f5Name`}
      />
    )
  },
  'FACULTY 1 URL': {
    prop: 'f1Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.f1Url`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 2 URL': {
    prop: 'f2Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.f2Url`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 3 URL': {
    prop: 'f3Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.f3Url`}
      />
    )
  },
  'FACULTY 4 URL': {
    prop: 'f4Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.f4Url`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'FACULTY 5 URL': {
    prop: 'f5Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.f5Url`}
      />
    )
  },
  'ASSESSMENT 1 NAME': {
    prop: 'a1Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.a1Name`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 2 NAME': {
    prop: 'a2Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.a2Name`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 3 NAME': {
    prop: 'a3Name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.a3Name`}
      />
    )
  },
  'ASSESSMENT 1 URL': {
    prop: 'a1Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.a1Url`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 2 URL': {
    prop: 'a2Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.a2Url`}
      />
    )
  },
  // This should be rewritten as just `type: [String]` for `CATEGORY`.
  'ASSESSMENT 3 URL': {
    prop: 'a3Url',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="url"
        name={`rows.${props.row.index}.a3Url`}
      />
    )
  },
  'COURSE DESCRIPTION': {
    prop: 'description',
    parse: parseText,
    required: true,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        required
        multiline
        autoRows
        name={`rows.${props.row.index}.description`}
      />
    )
  },
  'COURSE LEVEL': {
    prop: 'level',
    type: String,
    required: true,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        required
        type="select"
        options={COURSE_LEVEL_OPTIONS}
        validate={validateCourseLevel}
        name={`rows.${props.row.index}.level`}
      />
    )
  },
  'CREDITS': {
    prop: 'hours',
    type: Number,
    required: true,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        required
        type="number"
        validate={validateNonNegativeNumber}
        name={`rows.${props.row.index}.hours`}
      />
    )
  },
  'SYLLABUS': {
    prop: 'pathToSyllabus',
    type: URL,
    required: true,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        required
        type="url"
        name={`rows.${props.row.index}.pathToSyllabus`}
      />
    )
  },
  'PRE-REQUISITES': {
    prop: 'prerequisiteText',
    parse: parseText,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.prerequisiteText`}
      />
    )
  },
  'COURSE MATERIAL ISBNS': {
    prop: 'courseMaterialISBNs',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        validate={validateISBN}
        name={`rows.${props.row.index}.courseMaterialISBNs`}
      />
    )
  },
  'COURSE MATERIAL TITLES': {
    prop: 'courseMaterialTitles',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.courseMaterialTitles`}
      />
    )
  },
  'COURSE LOCATION': {
    prop: 'location',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.location`}
      />
    )
  },
  'COURSE DELIVERY METHOD': {
    prop: 'deliveryMethod',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={COURSE_DELIVERY_METHOD_OPTIONS}
        validate={validateCourseDeliveryMethod}
        name={`rows.${props.row.index}.deliveryMethod`}
      />
    )
  },
  'ADDITIONAL COSTS': {
    prop: 'additionalCosts',
    type: Boolean,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={YES_OR_NO_OPTIONS}
        name={`rows.${props.row.index}.additionalCosts`}
      />
    )
  },
  'TEXTBOOK COST': {
    prop: 'hasTextbookCost',
    type: Boolean,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={YES_OR_NO_OPTIONS}
        name={`rows.${props.row.index}.hasTextbookCost`}
      />
    )
  },
  'PREREQUISITES': {
    prop: 'prerequisiteText',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.prerequisiteText`}
      />
    )
  },
  'CERTIFICATES': {
    prop: 'certificates',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.certificates`}
      />
    )
  },
  'NOTES': {
    prop: 'notes',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        multiline
        autoRows
        name={`rows.${props.row.index}.notes`}
      />
    )
  },
  'COURSE COST': {
    prop: 'cost',
    required: true,
    type: Number,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        required
        type="currency"
        currency="USD"
        name={`rows.${props.row.index}.cost`}
      />
    )
  },
  'ON-DEMAND COURSE': {
    prop: 'onDemand',
    type: Boolean,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={YES_OR_NO_OPTIONS}
        name={`rows.${props.row.index}.onDemand`}
      />
    )
  }
};

export const SESSION_SCHEMA = {
  'START DATE': {
    prop: 'startDate',
    type: Date,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="date"
        validate={validateCourseSectionDate(props.row.index)}
        name={`rows.${props.row.index}.session.startDate`}
      />
    )
  },
  'END DATE': {
    prop: 'endDate',
    type: Date,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="date"
        validate={validateCourseSectionDate(props.row.index)}
        name={`rows.${props.row.index}.session.endDate`}
      />
    )
  },
  'ADD DATE': {
    prop: 'lastAddDate',
    type: Date,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="date"
        validate={validateCourseSectionDate(props.row.index)}
        name={`rows.${props.row.index}.session.lastAddDate`}
      />
    )
  },
  'DROP DATE': {
    prop: 'lastDropDate',
    type: Date,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="date"
        validate={validateCourseSectionDate(props.row.index)}
        name={`rows.${props.row.index}.session.lastDropDate`}
      />
    )
  },
  'TERM': {
    prop: 'term',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={TERM_OPTIONS}
        validate={validateTermName}
        name={`rows.${props.row.index}.session.term`}
      />
    )
  },
  'INTERNAL TERM': {
    prop: 'internalTerm',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.session.internalTerm`}
      />
    )
  },
  'SESSION': {
    prop: 'name',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        validate={validateSessionName}
        name={`rows.${props.row.index}.session.name`}
      />
    )
  },
  'INTERNAL SESSION': {
    prop: 'internalName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.session.internalName`}
      />
    )
  },
  'COST': {
    prop: 'cost',
    type: Number,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        required
        currency="USD"
        type="currency"
        name={`rows.${props.row.index}.session.cost`}
      />
    )
  }
};

export const SECTION_SCHEMA = {
  'SECTION': {
    prop: 'number',
    type: String,
    required: true,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        required
        name={`rows.${props.row.index}.session.section.number`}
      />
    )
  },
  'AVAILABLE SEATS': {
    prop: 'availableSeats',
    type: Integer,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        required
        type="number"
        name={`rows.${props.row.index}.session.section.availableSeats`}
      />
    )
  },
  'ACTIVE': {
    prop: 'isActive',
    type: Boolean,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="select"
        options={YES_OR_NO_OPTIONS}
        name={`rows.${props.row.index}.session.section.isActive`}
      />
    )
  },
  'DATE & TIME': {
    prop: 'schedule',
    parse(value) {
      if (!value) {
        return null;
      }
      if (value.toLowerCase() === 'asynchronous') {
        return null;
      }
      return value;
    },
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        name={`rows.${props.row.index}.session.section.schedule`}
      />
    )
  },
  'INSTRUCTOR FIRST NAME': {
    prop: 'instructorFirstName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="name"
        name={`rows.${props.row.index}.session.section.instructorFirstName`}
      />
    )
  },
  'INSTRUCTOR MIDDLE NAME': {
    prop: 'instructorMiddleName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="name"
        name={`rows.${props.row.index}.session.section.instructorMiddleName`}
      />
    )
  },
  'INSTRUCTOR LAST NAME': {
    prop: 'instructorLastName',
    type: String,
    field: (props) => (
      <FormField
        noMargin
        showErrorTooltip
        type="name"
        name={`rows.${props.row.index}.session.section.instructorLastName`}
      />
    )
  }
};

export const COURSE_UPLOAD_SCHEMA = {
  ...COURSE_SCHEMA,
  '<SESSION>': {
    prop: 'session',
    type: {
      ...SESSION_SCHEMA,
      '<SECTION>': {
        prop: 'section',
        type: SECTION_SCHEMA
      }
    }
  }
};

export function transformCourseUploadData(rows) {
  return rows.map(row => {
    // Set `category` property.
    //
    // "category" is a concatenation of "category 1", "category 2", "category 3".
    //
    // The property is historically called `category` because in the `courses` SQL table
    // the column is incorrectly called `category` instead of `categories`.
    //
    row.category = [row.c1, row.c2, row.c3].filter(_ => _).join(', ');
    delete row.c1;
    delete row.c2;
    delete row.c3;

    // Invert `zeroTextbookCost` property.
    row.zeroTextbookCost = invertBoolean(row.hasTextbookCost);
    delete row.hasTextbookCost;

    // Set `resources` property.

    row.resources = [];

    const facultyCredentialsAndLearningAssessments = [
      'f1',
      'f2',
      'f3',
      'f4',
      'f5',
      'a1',
      'a2',
      'a3'
    ];

    facultyCredentialsAndLearningAssessments.map((prefix) => {
      if (row[prefix + 'Name'] && row[prefix + 'Url']) {
        row.resources.push({
          name: row[prefix + 'Name'],
          url: row[prefix + 'Url'],
          type: prefix.startsWith('f') ? 'FACULTY_CREDENTIAL' : 'LEARNING_ASSESSMENT'
        });
      }
      delete row[prefix + 'Name'];
      delete row[prefix + 'Url'];
    });

    // Set `session.cost` from `course.cost` because there's only one "COST" column.
    if (row.session && (row.session.cost === undefined || row.session.cost === null)) {
      row.session.cost = row.cost;
    }
    return row;
  });
}

function invertBoolean(value) {
  if (value === true) {
    return false;
  }
  if (value === false) {
    return true;
  }
  return value;
}
