import isValidISBN from 'common-lib/lib/isValidISBN';

export function validateISBN(isbn) {
  if (typeof isbn !== 'string') {
    return 'ISBN must be a string';
  }

  const isbns = isbn.split(';').map(_ => _.trim());
  for (const isbn of isbns) {
    if (!isValidISBN(isbn)) {
      return `Invalid ISBN: ${isbn}`;
    }
  }
}
