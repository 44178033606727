import type { Id } from '@acadeum/types';
import {
  getInstitutionAffiliationOptionsByName,
  getInstitutionAffiliationOptionByValue
} from '../../../helpers/institutionAffiliation';

function getInstitutionAffiliationInputParams_({
  limit = 20,
  getInstitutionAffiliationOptionsByName,
  getInstitutionAffiliationOptionByValue
}: {
  limit?: number;
  getInstitutionAffiliationOptionsByName: (name: string, parameters: { limit: number }) => Promise<{ value: Id, label: string }[]>;
  getInstitutionAffiliationOptionByValue: (id: number) => Promise<{ value: Id, label: string } | null>;
}) {
  return {
    async: true,
    fetchOptions: async (name) => {
      return await getInstitutionAffiliationOptionsByName(name, {
        limit
      });
    },
    findOptionByValue: async (id) => {
      return await getInstitutionAffiliationOptionByValue(id);
    }
  };
}

export function getInstitutionAffiliationInputParams(parameters) {
  return getInstitutionAffiliationInputParams_({
    ...parameters,
    getInstitutionAffiliationOptionsByName,
    getInstitutionAffiliationOptionByValue
  });
}
