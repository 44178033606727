import React from 'react';
import { usePicker } from '../context';
import { fakePresets } from '../constants';

const Presets = ({ presets = [] }: { presets?: string[] }) => {
  const { value, onChange, handleChange } = usePicker();

  const getPresets = () => {
    if (presets?.length > 0) {
      return presets?.slice(0, 18);
    } else {
      return fakePresets;
    }
  };

  const handlePresetClick = (preset: string) => {
    if (preset?.includes('gradient')) {
      onChange(preset);
    } else {
      handleChange(preset);
    }
  };

  return (
    <div className="rbgcp-presets-wrap">
      <div
        style={{ background: value }}
        className="rbgcp-preset-color-preview"
      />
      <div className="rbgcp-presets-list">
        {getPresets().map((p: any, key: number) => (
          <div
            key={`${p}-${key}`}
            style={{
              background: p,
              border: p === 'rgba(255,255,255, 1)' ? '1px solid #96959c' : ''
            }}
            className="rbgcp-preset-color"
            onClick={() => handlePresetClick(p)}
          />
        ))}
      </div>
    </div>
  );
};

export default Presets;
