import type { FC } from 'react';
import React from 'react';
import classNames from 'classnames';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { CircleExclamationSolidIcon } from '@acadeum/icons';

import { BaseButton } from '../BaseButton';
import { Icon } from '../Icon';

import styles from './ErrorTooltip.module.scss';

export interface ErrorTooltipProps {
  className?: string;
  children: React.ReactNode;
}

export const ErrorTooltip: FC<ErrorTooltipProps> = ({
  className,
  children
}) => {
  return (
    <TooltipPrimitive.Provider
      delayDuration={0}
    >
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>
          <BaseButton className={classNames(styles.iconButton, className)}>
            <Icon icon={CircleExclamationSolidIcon}/>
          </BaseButton>
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            side="bottom"
            sideOffset={5}
            className={styles.content}
          >
            {children}
            <TooltipPrimitive.Arrow className={styles.arrow}/>
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
