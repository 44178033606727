import formatCourseLevel from 'common-lib/lib/formatCourseLevel.js';
import courseLevels from 'common-lib/constants/courseLevels.json';

import { getTranslationLanguage } from '../translate';

const language = getTranslationLanguage();

export const COURSE_LEVELS = courseLevels;

export const COURSE_LEVEL_OPTIONS = courseLevels.map((level) => ({
  value: level,
  label: formatCourseLevel(level, { language })
}));
