import { getAlgoliaIndex } from './algolia';

function getInstitutionAccreditationIndex() {
  return getAlgoliaIndex('institutionAccreditations');
}

export async function getInstitutionAccreditationOptionsByName(name: string, parameters: {
  limit?: number
}) {
  const { limit } = parameters;
  const institutionAccreditationProperties = await getInstitutionAccreditationIndex().findAll({
    query: name,
    limit: limit
  });

  return institutionAccreditationProperties.map(getPropertyOptionForInstitution);
}

export async function getInstitutionAccreditationOptionByValue(id: number) {
  const institutionAccreditationProperties = await getInstitutionAccreditationIndex().get(String(id));

  if (institutionAccreditationProperties) {
    return getPropertyOptionForInstitution(institutionAccreditationProperties);
  }

  return null;
}

function getPropertyOptionForInstitution({objectID, name}) {
  return {
    value: Number(objectID),
    label: name
  };
}
