import React from 'react';
import classNames from 'classnames';

import styles from '../List.module.scss';

interface ListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  children: React.ReactNode;
  className?: string;
}

export const Item: React.FC<ListItemProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <li
      {...rest}
      className={classNames(styles.Item, className)}
    >
      {children}
    </li>
  );
};
