import React from 'react';

import type { UseStepResult } from '@acadeum/hooks';

import type { AddCourseFormProps } from '../AddCourseForm';
import { AddCourseForm } from '../AddCourseForm';
import type { UploadCoursesProps } from '../UploadCourses';
import { UploadCourses } from '../UploadCourses';

import type { AddCourseMethod } from '../../types';

export type CourseDetailsProps = UploadCoursesProps & {
  method: AddCourseMethod;
  navigation: UseStepResult<unknown>['navigation'];
  cancelUrl: string;
  onBackForAddCourseForm: () => void;
  defaultFormValues: AddCourseFormProps['defaultValues']
  onSubmitCourseForm: AddCourseFormProps['onSubmit']
  onDemandCourseDropDateDaysAfterStartDate?: number;
  onDemandCourseEndDateDaysAfterStartDate?: number;
}

export const CourseDetailsStep = ({
  method,
  navigation,
  cacheOptions,
  // dataUploadPageRef,
  onCoursesUpload,
  onSubmitCourseForm,
  cancelUrl,
  onBackForAddCourseForm,
  defaultFormValues,
  useBulkCreateOrUpdate,
  // useFileDataImportErrorMutation,
  onDemandCourseDropDateDaysAfterStartDate,
  onDemandCourseEndDateDaysAfterStartDate
}: CourseDetailsProps) => {

  if (method === 'manual') {
    return (
      <>
        <AddCourseForm
          onDemandCourseDropDateDaysAfterStartDate={onDemandCourseDropDateDaysAfterStartDate}
          onDemandCourseEndDateDaysAfterStartDate={onDemandCourseEndDateDaysAfterStartDate}
          defaultValues={defaultFormValues}
          onSubmit={onSubmitCourseForm}
          onBack={onBackForAddCourseForm}
          onSuccessfulUpload={navigation.next}
          cancelUrl={cancelUrl}
        />
      </>
    );
  }

  return (
    <UploadCourses
      onCoursesUpload={onCoursesUpload}
      cacheOptions={cacheOptions}
      navigation={navigation}
      // dataUploadPageRef={dataUploadPageRef}
      useBulkCreateOrUpdate={useBulkCreateOrUpdate}
      // useFileDataImportErrorMutation={useFileDataImportErrorMutation}
    />
  );
};
