import { JobError } from './jobError';

const MAX_POLLING_INTERVAL = 5 * 60 * 1000;

interface PendingJob {
  status: 'SCHEDULED' | 'RUNNING';
}

interface FulfilledJob<T> {
  status: 'FINISHED';
  result: T;
}

interface RejectedJob {
  status: 'ERROR';
  result: { message?: string };
}

interface UnknownJobResult {
  status: unknown;
  result: never;
}

export type JobId = number;
export type JobResult<T> = PendingJob | FulfilledJob<T> | RejectedJob | UnknownJobResult;

// Using raw `Promise`s here instead of `await`
// to avoid potentially "call stack overflow" or something
// because no one knows what Babel does with `async`/`await` being the scenes.
export function waitForJob<T>(
  jobId: JobId,
  getJobStatus: (jobId: JobId) => Promise<JobResult<T>>,
  setCancel: (arg0: (() => void) | undefined) => void,
  pollingInterval = 2 * 1000
) {
  return getJobStatus(jobId).then((job: JobResult<T>) => {
    switch (job.status) {
      case 'FINISHED':
        setCancel(undefined);
        return job.result;
      case 'ERROR':
        setCancel(undefined);
        throw new JobError(job.result.message || 'Unexpected error. Please try again later or contact the Acadeum Support Team.', job.result);
      case 'SCHEDULED':
      case 'RUNNING':
        return new Promise((resolve, reject) => {
          const timer = setTimeout(() => {
            waitForJob(
              jobId,
              getJobStatus,
              setCancel,
              Math.min(pollingInterval * 1.1, MAX_POLLING_INTERVAL)
            ).then(resolve, reject);
          }, pollingInterval);
          setCancel(() => clearTimeout(timer));
        });
      default:
        throw new Error(`Unknown job status: ${job.status}`);
    }
  });
}
