import type { CSSProperties } from 'react';
import React from 'react';
import classNames from 'classnames';

import type { XsProp } from '../../utils/useSx';
import { useSx } from '../../utils/useSx';

import type { ResponsiveStyleValue } from './lib';
import { mergeIfContainer, useResponsiveAttributes } from './lib';

import styles from './Grid.module.scss';

export type GridDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type GridWrap = 'nowrap' | 'wrap' | 'wrap-reverse';
export type GridSize = 'auto' | 'grow' | number | false;
export type GridSpacing = number | string;
export type GridOffset = 'auto' | number;

export interface GridProps extends XsProp, Pick<React.HTMLAttributes<HTMLDivElement>, 'hidden' | 'className'> {
  className?: string;
  children?: React.ReactNode;
  columns?: ResponsiveStyleValue<number>;
  columnSpacing?: ResponsiveStyleValue<GridSpacing>;
  container?: boolean;
  direction?: ResponsiveStyleValue<GridDirection>;
  offset?: ResponsiveStyleValue<GridOffset>; // +
  rowSpacing?: ResponsiveStyleValue<GridSpacing>;
  size?: ResponsiveStyleValue<GridSize>; // +
  spacing?: ResponsiveStyleValue<GridSpacing | undefined>;
  wrap?: GridWrap;
  style?: CSSProperties;
}

export const Grid = React.forwardRef<HTMLDivElement, GridProps>(({
  className,
  children,
  columns = 12,
  container = false,
  direction = 'row',
  wrap = 'wrap',
  size = {},
  offset = {},
  spacing = 0,
  rowSpacing = spacing,
  columnSpacing = spacing,
  style,
  sx,
  ...rest
}, ref) => {
  const sxStyles = useSx(sx);
  const { attributes: sizeAttributes, style: sizeStyle } = useResponsiveAttributes(size, 'size');
  const { attributes: offsetAttributes, style: offsetStyle } = useResponsiveAttributes(offset, 'offset');
  const {
    style: rowSpacingStyle,
    childStyle: rowSpacingChildStyle,
    attributes: rowSpacingAttributes,
    childAttributes: rowSpacingChildAttributes
  } = useResponsiveAttributes(rowSpacing, 'row-spacing', 8);
  const {
    style: columnSpacingStyle,
    childStyle: columnSpacingChildStyle,
    attributes: columnSpacingAttributes,
    childAttributes: columnSpacingChildAttributes
  } = useResponsiveAttributes(columnSpacing, 'column-spacing', 8);
  const {
    style: directionStyle,
    attributes: directionAttributes
  } = useResponsiveAttributes(direction, 'flex-direction');
  const {
    style: columnsStyle,
    childStyle: columnsChildStyle,
    attributes: columnsAttributes,
    childAttributes: columnsChildAttributes
  } = useResponsiveAttributes(columns, 'columns');

  const cssVariables: CSSProperties = {
    ...mergeIfContainer(container, [rowSpacingStyle, columnSpacingStyle, { '--Grid-wrap': wrap }, columnsStyle]),
    ...offsetStyle,
    ...sizeStyle,
    ...directionStyle
  };

  const childCssVariables: CSSProperties = mergeIfContainer(container, [
    rowSpacingChildStyle,
    columnSpacingChildStyle,
    columnsChildStyle
  ]);

  return (
    <div
      ref={ref}
      {...rest}
      {...sizeAttributes}
      {...offsetAttributes}
      {...columnsAttributes}
      {...rowSpacingAttributes}
      {...columnSpacingAttributes}
      {...directionAttributes}
      className={classNames(styles.root, className, {
        [styles.container]: container
      })}
      style={{
        ...sxStyles,
        ...style,
        ...cssVariables
      }}
    >
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            // @ts-expect-error
            style: {
              ...child.props.style,
              ...childCssVariables
            },
            ...columnsChildAttributes,
            ...rowSpacingChildAttributes,
            ...columnSpacingChildAttributes
          });
        }
        return child;
      })}
    </div>
  );
});
