import type { CSSProperties } from 'react';
import { useMemo } from 'react';

import type { Breakpoint } from '@acadeum/hooks';
import { useScreenSize } from '@acadeum/hooks';
import { isObject } from '@acadeum/helpers';

type ResponsiveStyleValue<T> = T | { [key in Breakpoint]?: T | null };

type SxProps = {
  [Key in keyof CSSProperties]?: ResponsiveStyleValue<CSSProperties[Key]>;
};

export interface XsProp {
  sx?: SxProps;
}

const orderedBreakpoints: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'];

function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function useSx(sx?: SxProps) {
  const { screenSizes } = useScreenSize();
  return useMemo(() => {
    if (!screenSizes || !sx) {
      return;
    }

    const css: CSSProperties = {};

    for (const [property, value] of Object.entries(sx)) {
      if (isObject(value)) {
        for (const breakpoint of orderedBreakpoints) {
          if (screenSizes[breakpoint] && isDefined(value[breakpoint])) {
            css[property] = value[breakpoint];
          }
        }
      } else if (isDefined(value)) {
        css[property] = value;
      }
    }

    return css;
  }, [screenSizes, sx]);
}
