import type { Id } from '@acadeum/types';
import {
  getInstitutionAccreditationOptionsByName,
  getInstitutionAccreditationOptionByValue
} from '../../../helpers/institutionAccreditation';

function getInstitutionAccreditationInputParams_({
  limit = 20,
  getInstitutionAccreditationOptionsByName,
  getInstitutionAccreditationOptionByValue
}: {
  limit?: number;
  getInstitutionAccreditationOptionsByName: (name: string, parameters: { limit: number }) => Promise<{ value: Id, label: string }[]>;
  getInstitutionAccreditationOptionByValue: (id: number) => Promise<{ value: Id, label: string } | null>;
}) {
  return {
    async: true,
    fetchOptions: async (name) => {
      return await getInstitutionAccreditationOptionsByName(name, {
        limit
      });
    },
    findOptionByValue: async (id) => {
      return await getInstitutionAccreditationOptionByValue(id);
    }
  };
}

export function getInstitutionAccreditationInputParams(parameters) {
  return getInstitutionAccreditationInputParams_({
    ...parameters,
    getInstitutionAccreditationOptionsByName,
    getInstitutionAccreditationOptionByValue
  });
}
