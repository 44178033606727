// Sentry initialization should be imported first!
import './sentry.js';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

// A global utility for tracking the current input method (mouse, keyboard or touch).
// https://github.com/ten1seven/what-input/
import 'what-input';

import { onNavigate } from './helpers/googleAnalytics';

import { setStore } from './store.js';

// Run tests.
import { render } from 'react-pages/client';
import reactPagesConfig from './react-pages.js';

TimeAgo.addDefaultLocale(en);

// The rest of the code here can be removed after migrating to Next.js.
// https://github.com/Acadeum/Tickets/issues/1009

async function run() {
  // Renders the webpage on the client side
  const { enableHotReload } = await render(reactPagesConfig, {
    // Google Analytics: track page views.
    onPageRendered: onNavigate,
    // The `store` is used in `./src/actions.js`.
    onStoreCreated: setStore
  });

  // Enables hot-reload via Webpack "Hot Module Replacement".
  if (import.meta.webpackHot) {
    enableHotReload();
  }
}

run().catch((error) => {
  console.error(error);
});
