import React, { useRef } from 'react';
import type { MutableRefObject } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import type { FormRef } from '@acadeum/ui';
import { Form, FormField, FormSubmit, Button, FormFooter, FormRow, ContentSection } from '@acadeum/ui';

export default function StudentUserForm({
  values,
  cancelLocation,
  submitText,
  onSubmit,
  className
}) {
  const form = useRef() as MutableRefObject<FormRef>;

  return (
    <Form
      ref={form}
      autoFocus
      className={classNames('form', className)}
      onSubmit={onSubmit}
    >

      <ContentSection title="Personal Information">
        <FormRow>
          <FormField
            required
            type="name"
            name="firstName"
            label="First Name"
            defaultValue={values && values.firstName}
          />

          <FormField
            required
            type="name"
            name="lastName"
            label="Last Name"
            defaultValue={values && values.lastName}
          />

          <FormField
            name="hiStudentId"
            label="Student ID"
            labelTooltip="This is the student's ID at your university. Each student is later given a unique Acadeum ID."
            defaultValue={values && values.hiStudentId}
          />

          <FormField
            required={!(values && values.email)}
            name="email"
            type="email"
            label="Student Email"
            defaultValue={values && values.email}
            disabled={values && values.email ? true : undefined}
          />
        </FormRow>
      </ContentSection>

      <FormFooter>
        <Button
          variant="secondary"
          url={cancelLocation}>
          Cancel
        </Button>

        <FormSubmit>
          {submitText}
        </FormSubmit>
      </FormFooter>
    </Form>
  );
}

StudentUserForm.propTypes = {
  values: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  cancelLocation: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  submitText: PropTypes.string.isRequired,
  origin: PropTypes.object,
  className: PropTypes.string
};
