import React from 'react';

import config from 'config';

import { userHasPermission } from '@acadeum/helpers';
import { AuthenticationPage } from '@acadeum/shared-admin-ui';

import { useFetchSingleSignOnConfigQuery, useResetSingleSignOnConfigMutation } from '../../api/authenticationSettings';
import type { AppPage } from '../../helpers/app.types';
import getAuthCookieConfig from '../../helpers/getAuthCookieConfig';

const Authentication: AppPage = () => {
  return (
    <AuthenticationPage
      authCookieConfig={getAuthCookieConfig()}
      useFetchSingleSignOnConfigQuery={useFetchSingleSignOnConfigQuery}
      useResetSingleSignOnConfigMutation={useResetSingleSignOnConfigMutation}
      apiUrl={config.apiUrl}
    />
  );
};

Authentication.meta = () => ({
  title: 'Authentication'
});

Authentication.when = ({ user }) => userHasPermission(user, 'authenticationSettings:read', {
  ownerId: null,
  orgId: user?.institution.id
});


export default Authentication;
