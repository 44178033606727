import type { CSSProperties } from 'react';
import { useMemo } from 'react';

import { isObject } from '@acadeum/helpers';

export type ResponsiveStyleValue<T> = T | { [key in Breakpoint]?: T | null };

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

export function useResponsiveAttributes(
  value: ResponsiveStyleValue<any>,
  baseName: string,
  multiplier?: number
) {
  return useMemo(() => {
    const attributes: { [key: string]: string | number } = {};
    const style: CSSProperties = {};
    const childAttributes: { [key: string]: string | number } = {};
    const childStyle: CSSProperties = {};

    const applyMultiplier = (val: any) =>
      typeof val === 'number' && multiplier ? `${val * multiplier}px` : val;

    if (typeof value === 'string' || typeof value === 'number') {
      const finalValue = applyMultiplier(value);
      attributes[`data-grid-${baseName}-xs`] = typeof value === 'number' ? 'number' : value;
      style[`--Grid-${baseName}-xs`] = finalValue;
      childAttributes[`data-grid-parent-${baseName}-xs`] =typeof value === 'number' ? 'number' : value;
      childStyle[`--Grid-parent-${baseName}-xs`] = finalValue;
    } else if (isObject(value)) {
      for (const [breakpoint, val] of Object.entries(value)) {
        if (val !== null) {
          const finalValue = applyMultiplier(val);
          const attrValue = typeof val === 'number' ? 'number' : val;
          attributes[`data-grid-${baseName}-${breakpoint}`] = attrValue;
          style[`--Grid-${baseName}-${breakpoint}`] = finalValue;
          childAttributes[`data-grid-parent-${baseName}-${breakpoint}`] = attrValue;
          childStyle[`--Grid-parent-${baseName}-${breakpoint}`] = finalValue;
        }
      }
    }

    return { attributes, style, childAttributes, childStyle };
  }, [value, baseName, multiplier]);
}

export function mergeIfContainer(container: boolean, styles: Record<string, any>[]) {
  return container ? Object.assign({}, ...styles) : {};
}
