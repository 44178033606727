import type { FC } from 'react';
import React, { useImperativeHandle, useState, useRef } from 'react';
import classNames from 'classnames';
import { ColorPicker } from '../GradientColorPicker';

import { CaretDownIcon } from '@acadeum/icons';
import { useRect } from '@acadeum/hooks';

import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import { BaseButton } from '../BaseButton';
import { HStack } from '../Stack';
import { Icon } from '../Icon';

import styles from './ColorInput.module.scss';

export interface ColorInputProps {
  className?: string;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
  label: string;
}

export const ColorInput: FC<ColorInputProps> = React.forwardRef(({
  value,
  onChange,
  disabled,
  readOnly,
  label
}, ref) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const rect = useRect(buttonRef);
  useImperativeHandle(ref, () => ({
    focus: () => {
      /* noop */
    }
  }));

  const width = typeof rect?.width === 'number' ? rect.width - 32 : undefined;

  const [open, setOpen] = useState(false);

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <BaseButton
            ref={buttonRef}
            className={styles.Toggle}
            disabled={disabled || readOnly}
            aria-label={label}
          >
            <HStack as="span" gap="sm">
              <div
                className={styles.preview}
                style={{ background: value }}
              />
              {value?.includes('gradient') ? 'Gradient' : `Solid - ${value}`}
            </HStack>
            <Icon
              icon={CaretDownIcon}
              className={classNames({
                [styles.hidden]: readOnly
              })}
            />
          </BaseButton>
        </PopoverTrigger>
        <PopoverContent className={styles.PopoverContent}>
          <ColorPicker
            className={styles.ColorPicker}
            width={width}
            height={136}
            value={value}
            onChange={onChange}
            hideColorGuide
            hideInputType
            hideAdvancedSliders
          />
        </PopoverContent>
      </Popover>
    </>
  );
});
