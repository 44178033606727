import { useId, useState } from 'react';
import classNames from 'classnames';

import {
  DatePicker,
  Icon,
  Input,
  Tag,
  Tooltip,
  Select
} from '@acadeum/ui';
import { CircleExclamationSolidIcon } from '@acadeum/icons';
import { normalizeOutboundDate } from '@acadeum/helpers';
import { ETHNICITY_OPTIONS, STUDENT_LEVEL_OPTIONS, YES_OR_NO_OPTIONS } from '@acadeum/selection-options';

import styles from './StudentsDataUploadPage.module.scss';

export function getIgnoredColumns(columns, schema) {
  columns = columns.slice();
  removeInSchemaColumns(columns, schema);
  return columns;
}

export function removeInSchemaColumns(columns, schema) {
  for (let property of Object.keys(schema)) {
    if (typeof schema[property].type === 'object') {
      removeInSchemaColumns(columns, schema[property].type);
    } else {
      // Remove an optional "required" asterisk ("*") at the end.
      property = property.replace(/\*$/, '');
      const index = columns.indexOf(property);
      if (index >= 0) {
        columns.splice(index, 1);
        if (columns.indexOf(property) >= 0) {
          console.error(`Duplicate column "${property}" found in the uploaded file`);
        }
      }
    }
  }
}

const Cell = ({ getValue, row, column, table, schema, getCellError, t }) => {
  const initialValue = getValue();
  const tooltipValue = initialValue;
  const [value, setValue] = useState(initialValue);

  const tooltipId = `tooltip${useId()}`;

  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      setValue(e.value);
      onBlur();
    }
  };

  let errorIcon;
  const error = getCellError(row);

  if (error) {
    let key = error.reason ? error.reason : error.error;

    const match = key.match(/(min_date|max_date)_(.*)/);
    if (match) {
      key = match[1];
    }
    const date = match ? new Date(match[2]) : null;

    if (!match) {
      key = parseErrorToTranslateKey(key);
    }

    errorIcon = (
      <Tooltip
        id={tooltipId}
        content={t(`tooltip.${key}`, {
          lineBreak: () => <br />,
          date,
          value: tooltipValue
        })}
      >
        <Icon className={styles.StudentsDataUploadPage__errorIcon} icon={CircleExclamationSolidIcon} />
      </Tooltip>
    );
  }

  if (schema?.type === Date) {
    return (
      <DatePicker
        id="date-picker"
        error={error?.error}
        value={value instanceof Date ? normalizeOutboundDate(value, { utc: true }) : undefined}
        tooltipProps={{
          id: tooltipId,
          content: errorIcon
        }}
        onChange={(date) => setValue(date)}
        onBlur={onBlur}
        onSelect={(date) => table.options.meta?.updateData(row.index, column.id, date)}
      />
    );
  }

  if (schema?.prop === 'citizenship') {
    return (
      <Select
        className={styles.StudentsDataUploadPage__citizenship}
        error={error?.error}
        tooltipProps={{
          id: tooltipId,
          content: errorIcon
        }}
        value={typeof value === 'string' ? schema.parse(value) : value}
        options={YES_OR_NO_OPTIONS}
        onChange={(value) => {
          setValue(value);
          table.options.meta?.updateData(row.index, column.id, value ? 'Y' : 'N');
        }}
      />
    );
  }

  if (schema?.prop === 'level') {
    return (
      <Select
        className={styles.StudentsDataUploadPage__ethnicity}
        error={error?.error}
        tooltipProps={{
          id: tooltipId,
          content: errorIcon
        }}
        value={schema.parse ? schema.parse(value) : value}
        options={STUDENT_LEVEL_OPTIONS}
        onChange={(value) => {
          setValue(value);
          table.options.meta?.updateData(row.index, column.id, value);
        }}
      />
    );
  }

  if (schema?.prop === 'ethnicity') {
    return (
      <Select
        className={styles.StudentsDataUploadPage__ethnicity}
        error={error?.error}
        tooltipProps={{
          id: tooltipId,
          content: errorIcon
        }}
        value={value ? schema.parse(value) : 'Not Reported'}
        options={ETHNICITY_OPTIONS}
        onChange={(value) => {
          const selectedOption = ETHNICITY_OPTIONS.find(option => option.value === value);
          if (selectedOption) {
            setValue(value);
            table.options.meta?.updateData(row.index, column.id, selectedOption.label);
          }
        }}
      />
    );
  }

  return (
    <Input
      className={classNames({
        ['StudentsDataUploadPage__error']: error?.error
      })}
      error={error?.error}
      addonSuffix={errorIcon}
      value={value || ''}
      placeholder={error?.error === 'required' && !error?.value ? 'Required' : undefined}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
};

export function createHeaderColumnDescriptor(columnName, index, errors, getColumnSchema, t) {
  const schema = getColumnSchema(columnName);
  const getCellError = (row) => {
    return schema && errors.filter(error => error.column === columnName && error.row === row.index + 2)[0];
  };

  return {
    id: columnName,
    header: columnName,
    accessorFn: row => row[index],
    enableSorting: true,
    cell: ({ getValue, row, column, table }) => <Cell getValue={getValue} column={column} table={table} row={row} schema={schema} getCellError={getCellError} t={t}/>,
    meta: {
      getCellHasError: ({ row }) => !!getCellError(row)
    }
  };
}

function parseErrorToTranslateKey(error) {
  if (!error) {
    return null;
  }

  let parts = error.split(':');
  const key = parts[0];
  parts = key.split(' ').map(_ => _.toLowerCase());
  return parts.join('_');
}

export function parseHeaders({
  headerRow,
  errors,
  expectedResult,
  getColumnSchema,
  schema,
  t
}) {
  const headers = headerRow.map((columnName, index) => {
    return createHeaderColumnDescriptor(columnName, index, errors, getColumnSchema || (column => schema[column]), t);
  });

  headers.push({
    header: t('status'),
    accessorKey: 'status',
    cell: ({ row }) => {
      const hasError = errors.filter(error => error.row === row.index + 2).length > 0;
      const status = expectedResult && expectedResult.length > 0 ? expectedResult[row.index].status : undefined;

      return status ? (
        <Tooltip placement="left" content={t(`statusTooltip.${status.toLowerCase()}`)}>
          <Tag
            className={styles.DataUploadPage__statusTag}
            variant={status}
          />
        </Tooltip>
      ) : hasError ? (
        <Tooltip placement="left" content={t('statusTooltip.failed')}>
          <Tag
            className={styles.DataUploadPage__statusTag}
            variant="failed"
          />
        </Tooltip>
      ) : (
        <Tooltip placement="left" content={t('statusTooltip.succeeded')}>
          <Tag
            className={styles.DataUploadPage__statusTag}
            variant="succeeded"
          />
        </Tooltip>
      );
    }
  });

  return headers;
}
